import React from "react";
import GlobalTimerStore from "store/globalTimer";

function Header() {
  const { hours, minutes, seconds } = GlobalTimerStore();

  return (
    <div>
      <div class="navbar-countdown gradient-holiday">
        <div class="holiday-last-chance">
          <div class="navbar-sale-inner">
            <div class="new-navbar-containers is-black">
              <span id="listicle_sale_name" class="holiday-banners">
                <strong>BLACK FRIDAY SALE!</strong>
              </span>
            </div>
            <div class="text-block-209">
              Up to 60% PLUS Get FREE Shipping On All Bundles
              <br />
            </div>
          </div>
          <div class="div-block-874">
            <div id="js-clock" class="holiday-clock">
              <div class="box-2 hide">
                <div id="js-clock-days" class="clock-number-2">
                  00
                </div>
                <div class="clock-label-2">Days</div>
              </div>
              <div class="text-block-210 hide">:</div>
              <div class="box-2">
                <div id="js-clock-hours" class="clock-number-2">
                  {hours}
                </div>
                <div class="clock-label-2">Hrs</div>
              </div>
              <div class="text-block-210">:</div>
              <div class="box-2">
                <div id="js-clock-minutes" class="clock-number-2">
                  {minutes}
                </div>
                <div class="clock-label-2">Min</div>
              </div>
              <div class="text-block-210">:</div>
              <div class="box-2">
                <div id="js-clock-seconds" class="clock-number-2">
                  {seconds}
                </div>
                <div class="clock-label-2">Sec</div>
              </div>
              <div class="hide-2 w-embed w-script"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="globals-checkout w-embed"></div>
      <div class="div-block-881">
        <div class="spinner-wrapper">
          <div class="div-block-573">
            <div class="spinner"></div>
            <div class="icon-10 w-embed">
              <svg
                width="420"
                height="420"
                viewbox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 8V7C6 5.4087 6.63214 3.88258 7.75736 2.75736C8.88258 1.63214 10.4087 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7V8H20C20.2652 8 20.5196 8.10536 20.7071 8.29289C20.8946 8.48043 21 8.73478 21 9V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V9C3 8.73478 3.10536 8.48043 3.29289 8.29289C3.48043 8.10536 3.73478 8 4 8H6ZM19 10H5V20H19V10ZM11 15.732C10.6187 15.5119 10.3207 15.1721 10.1522 14.7653C9.98376 14.3586 9.9542 13.9076 10.0681 13.4823C10.1821 13.057 10.4332 12.6813 10.7825 12.4132C11.1318 12.1452 11.5597 11.9999 12 11.9999C12.4403 11.9999 12.8682 12.1452 13.2175 12.4132C13.5668 12.6813 13.8179 13.057 13.9319 13.4823C14.0458 13.9076 14.0162 14.3586 13.8478 14.7653C13.6793 15.1721 13.3813 15.5119 13 15.732V18H11V15.732ZM8 8H16V7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7V8Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
          <div class="text-block-157">Loading Secure Checkout</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
