import React, { useEffect, useMemo, useRef, useState } from "react";
import ProductDetails from "./product-details";
import DetailsHeaader from "./detailsHeader";
import Trust from "./trust";
import Paypal from "./paypal";
import Stripe from "./stripe";
import NMI from "./nmi";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import mainStore from "store/store";
import { trackCheckoutStartedEvent } from "mixpanel/events";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PurchaseStore from "store/purchase";

// const stripePromise = loadStripe(
//   "pk_test_51PXMSS2KPcpclTRGJGl7H0AD7g1zUTzVpUtoupo0jrVkv5XdCWsCHZPfCChoGrhrGXNRlfKIsTT5W3iuQI0OpDaN00Ti8WXYTy"
// );

function Details() {
  const {
    allProductOffers,
    paymentIntent,
    allPages,
    setAllPages,
    setPaymentIntent,
    setVoluumCid,
  } = mainStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { managePurchase } = PurchaseStore();
  const [bundleList, setBundleList] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState("NZ1002");
  const [upgradeToKing, setUpgradeToKing] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const summaryRef = useRef(null);
  const [summaryBundle, setSummaryBundle] = useState({
    sku: "",
    quantity: 0,
    label: "",
    retailValue: 0,
    afterDiscountValue: 0,
    shippingCost: 0,
    img: "",
  });

  useEffect(() => {
    initializeCheckout();
  }, []);

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location.pathname]);

  const initializeCheckout = async () => {
    console.log("window", window);
    if (
      window.dtpCallback &&
      typeof window.dtpCallback.getClickID === "function"
    ) {
      const clickID = window.dtpCallback.getClickID();
      console.log("dpt Click ID:", clickID);
      setVoluumCid(clickID);
    } else {
      console.warn(
        "window.dtpCallback.getClickID is not defined or not a function"
      );
    }

    if (allPages.includes("checkout") && paymentIntent) {
      setLoadingGlobal(true);
      const newPaymentIntent = { ...paymentIntent };
      newPaymentIntent.finalizePurchase = true;
      const res = await managePurchase(newPaymentIntent);
      if (res?.success) {
        setPaymentIntent(res.data);
        navigate("/thank-you");
      }
      setLoadingGlobal(false);
    }
    setLoadingGlobal(false);

    const pages = [...allPages];
    pages.push("checkout");
    setAllPages(pages);

    trackCheckoutStartedEvent();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    const defaultBundle = allProductOffers.offers.find((offer) => {
      return offer.sku === selectedBundle;
    });
    if (allProductOffers.offers && defaultBundle) {
      setSummaryBundle(defaultBundle);
    }
    setBundleList(allProductOffers.offers);
  }, [allProductOffers]);

  useEffect(() => {
    const bundle = bundleList.find((row) => {
      return row.sku === selectedBundle;
    });

    if (bundle) {
      console.log("useEffect ~ bundle:", bundle);
      setSummaryBundle(bundle);
    }
  }, [selectedBundle]);

  useEffect(() => {
    const bundle = bundleList.find((row) => {
      return row.sku === selectedBundle;
    });

    if (bundle) {
      const prevBundle = filteredOffers.find((offer) => {
        return offer.quantity === bundle.quantity;
      });
      setSelectedBundle(prevBundle.sku);
    }
  }, [upgradeToKing]);

  const filteredOffers = useMemo(
    () =>
      bundleList.filter((bundle) => {
        return upgradeToKing
          ? ["NZ1004", "NZ1005", "NZ1006"].includes(bundle.sku)
          : ["NZ1001", "NZ1002", "NZ1003"].includes(bundle.sku);
      }),
    [bundleList, upgradeToKing]
  );

  const returnTotal = useMemo(() => {
    return summaryBundle.afterDiscountValue + summaryBundle.shippingCost;
  }, [summaryBundle, upgradeToKing]);

  const returnTotalIfShippingIncluded = useMemo(() => {
    return summaryBundle.afterDiscountValue;
  }, [summaryBundle, upgradeToKing]);

  const returnSaveTotal = (bundle) => {
    return bundle.retailValue - bundle.afterDiscountValue;
  };

  const returnSaveTotalSummay = (bundle) => {
    return bundle.retailValue - bundle.afterDiscountValue;
  };

  const handleOptionChange = (event) => {
    // console.log("handleOptionChange ~ event:", event);
    // console.log("deal ~ bundleList:", bundleList);
    // const deal = bundleList.find((bundle) => {
    //   return bundle.type === event.type;
    // });
    setSelectedBundle(event.sku);
  };

  const handleCheckboxChange = (event) => {
    setUpgradeToKing(event.target.checked);
  };

  const handleScroll = () => {
    summaryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    // <div className="WorkingArea">
    //   <div className="container">
    //     <ProductDetails />
    //     <div className="ReadySection">
    //       <img src="img/courier.svg" alt="Icon" />
    //       <div className="Content">
    //         <h2>
    //           <span></span>READY TO SHIP
    //         </h2>
    //         <p>
    //           Delivered by Friday, Jul 5th from USPS order within{" "}
    //           <span>3 hrs 6 mins</span>
    //         </p>
    //       </div>
    //     </div>
    //     {/* Bundle & Save Section
    //     ================================================== --> */}
    //     <div className="BundleSection">
    //       <h2>BUNDLE & SAVE</h2>
    //       <div className="RadioButton" onClick={handleScroll}>
    //         {filteredOffers.map((bundle, index) => (
    //           <div className="Button" key={index}>
    //             <input
    //               id={`bundle-${bundle.sku}`}
    //               type="radio"
    //               value={bundle.sku}
    //               checked={selectedBundle === bundle.sku}
    //               onChange={handleOptionChange}
    //             />
    //             <label for={`bundle-${bundle.sku}`} className="Content">
    //               <div className="Heading">
    //                 <h3>
    //                   {bundle.quantity} Pillows
    //                   {bundle.shippingCost === 0 && (
    //                     <span>{"Free Shipping"}</span>
    //                   )}
    //                 </h3>
    //                 <p>{bundle.label}</p>
    //               </div>
    //               <div className="Value">
    //                 <span className="Price">
    //                   Retail ${bundle.retailValue?.toFixed(2)}
    //                 </span>
    //                 <h3>
    //                   $
    //                   {(bundle.afterDiscountValue / bundle.quantity).toFixed(2)}
    //                   {bundle.shippingCost === 0 ? (
    //                     "/ea"
    //                   ) : (
    //                     <span>+${bundle.shippingCost?.toFixed(2)} S/H</span>
    //                   )}
    //                 </h3>
    //                 <p>(Save ${returnSaveTotal(bundle).toFixed(2)} Total)</p>
    //               </div>
    //             </label>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //     <div className="OrderSection" ref={summaryRef}>
    //       <h2>
    //         <b>Order now</b>.. only <span>8</span> left in stock
    //       </h2>
    //       <div className="Upgrade">
    //         <h3>UPGRADE TO KING</h3>
    //         <div className="Content">
    //           <img src="img/large_pillow.png" alt="Pillow" />
    //           <div className="Arrow">
    //             <img src="img/arrow_red.svg" alt="Arrow" />
    //           </div>
    //           <div className="Checkbox">
    //             <input
    //               id="upgradeToKing"
    //               type="checkbox"
    //               checked={upgradeToKing}
    //               onChange={handleCheckboxChange}
    //             />
    //             <label for="upgradeToKing">
    //               <h4>Yes, upgrade my order for just</h4>
    //               <span>$9.99 per pillow!</span>
    //             </label>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="SummarySection">
    //       <div className="Heading">
    //         <h2>
    //           Hide order summary
    //           <svg viewBox="0 0 16 16">
    //             <path
    //               fill-rule="evenodd"
    //               d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
    //             />
    //           </svg>
    //         </h2>
    //         <span>${returnTotal.toFixed(2)}</span>
    //       </div>
    //       <div className="Content">
    //         <div className="Image">
    //           <div className="Price">
    //             <img src={summaryBundle.img} alt="Pillow" />
    //             <p>
    //               {summaryBundle.quantity} PILLOWS x{summaryBundle.quantity}{" "}
    //               Nuzzle Pillows
    //             </p>
    //           </div>
    //           <span>${summaryBundle.retailValue.toFixed(2)}</span>
    //         </div>
    //         <ul className="Value">
    //           <li>
    //             <span>You Save</span>
    //             <span>${returnSaveTotalSummay(summaryBundle).toFixed(2)}</span>
    //           </li>
    //           <li>
    //             <span>Sub Total ({summaryBundle.quantity} item)</span>
    //             <span>${returnTotalIfShippingIncluded.toFixed(2)}</span>
    //           </li>
    //           <li>
    //             <span>Shipping</span>
    //             <span>
    //               {summaryBundle.shippingCost === 0
    //                 ? "FREE SHIPPING"
    //                 : `$${summaryBundle.shippingCost.toFixed(2)}`}
    //             </span>
    //           </li>
    //           <li>
    //             <span>Total</span>
    //             <span>${returnTotal.toFixed(2)}</span>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //     <div className="ExpressCheckout">
    //       <h2>Express Checkout</h2>
    //       <Paypal
    //         offer={summaryBundle}
    //         amount={returnTotal}
    //         upgradedToKing={upgradeToKing}
    //       />
    //     </div>
    //     <div className="OrSection">
    //       <h2>OR</h2>
    //     </div>
    //     <Elements stripe={stripePromise}>
    //       <Stripe
    //         offer={summaryBundle}
    //         amount={returnTotal}
    //         upgradedToKing={upgradeToKing}
    //       />
    //     </Elements>
    //     <Trust />
    //   </div>
    // </div>
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div class="checkout-content">
        <DetailsHeaader />
        <div class="w-layout-blockcontainer new-container-box w-container">
          <div class="checkout-container">
            <div class="rtc-checkout-3 gr1 ds1 dw1">
              <div class="main-checkout-box newbox">
                <ProductDetails />
                <div class="new_checkout_div newstyle">
                  <div class="new_first newup">
                    <div class="div-block-595 leftnew changestick">
                      <div class="new-selection-div cop1">
                        <div class="sub-tab">
                          <div class="tabs-2 price-column w-tabs">
                            <div
                              class="tabs-menu-2 w-tab-menu"
                              onClick={handleScroll}
                            >
                              {filteredOffers.map((bundle, index) => (
                                <div>
                                  <a
                                    class={`checkout-select-variant-2 _2_bottles nuzzle2 gonew zx2 w-inline-block w-tab-link ${
                                      selectedBundle === bundle.sku
                                        ? "w--current"
                                        : ""
                                    }`}
                                    key={index}
                                  >
                                    <div
                                      data-scroll-time="1.25"
                                      class="select-box-div ff2 da2"
                                      onClick={() => handleOptionChange(bundle)}
                                    >
                                      <div class="checkbox-radio cb-sq tt2 ju2 pr2">
                                        <div class="check-circle-new sq1 chc1">
                                          {selectedBundle === bundle.sku && (
                                            <img
                                              loading="lazy"
                                              src="../images/checkmark-cards.svg"
                                              alt=""
                                              class="check-white-6"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div class="sel-top-box aa1 uy1">
                                        <div class="pro-tt-div in-per">
                                          <div class="tt-pro-txt">
                                            {" "}
                                            {bundle.quantity} Pillows
                                          </div>
                                          <div class="save-below-text">
                                            {bundle.label}
                                          </div>
                                        </div>
                                        {bundle.shippingCost === 0 && (
                                          <div class="ff-div-cen">
                                            <div class="ff-txt">
                                              Free Shipping
                                            </div>
                                          </div>
                                        )}
                                        <div class="sel-left-div q1">
                                          <div class="pre-div"></div>
                                          <div class="sel-bot-inner gy1 fd1 gh1">
                                            <div class="sbi-left">
                                              <div class="re-tol-div bv1">
                                                <p class="bb-pri">
                                                  Retail $
                                                  {bundle.retailValue?.toFixed(
                                                    2
                                                  )}
                                                </p>
                                              </div>
                                              <div class="un-pp">
                                                <span class="unit-price-new">
                                                  $
                                                  {(
                                                    bundle.afterDiscountValue /
                                                    bundle.quantity
                                                  ).toFixed(2)}
                                                  {bundle.shippingCost === 0 &&
                                                    "/ea"}
                                                </span>
                                                {bundle.shippingCost > 0 && (
                                                  <span class="sh-txt-new">
                                                    +$
                                                    {bundle.shippingCost?.toFixed(
                                                      2
                                                    )}{" "}
                                                    S/H
                                                  </span>
                                                )}
                                              </div>
                                              <div class="save-on-total-text">
                                                (Save $
                                                {returnSaveTotal(
                                                  bundle
                                                ).toFixed(2)}{" "}
                                                Total)
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {index == 1 && (
                                          <img
                                            loading="lazy"
                                            src="../images/ds1.png"
                                            alt=""
                                            class="pp-badge"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div class="best-seller-inner">
                            <div class="skeleton-load"></div>
                            <div class="pricing-container"></div>
                          </div>
                        </div>
                      </div>
                      <div id="order_summary_div" class="rem-div">
                        <div class="rem-txt">
                          <span class="text-span-139">Order now</span>.. only{" "}
                          <span class="text-span-138">8</span> left in stock
                        </div>
                      </div>
                      <div
                        id="upgrade_div"
                        class="div-block-602 nnpot2 re1 eq1"
                      >
                        <div class="up-div">
                          <div class="upgrad-heading-text">UPGRADE TO KING</div>
                        </div>
                        <div class="div-block-599">
                          <div class="addon-selector new-one">
                            <div class="w-layout-grid grid-32 vertical">
                              <div
                                id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e73f-51e295ef"
                                class="div-block-585"
                              >
                                <div class="text-block-69-copy">
                                  BREATHABLE, DURABLE MESH FABRIC
                                </div>
                                <p class="bump-description-copy">
                                  Made wit ha more breathable, durable mesh
                                  fabric allowing for better airflow which means
                                  extra cooling comfort and durability. Upgrade
                                  each of your BeActive Plus braces to our
                                  premium Deluxe version for just an additional
                                  $10 per brace you ordered!
                                  <br />
                                </p>
                              </div>
                              <img
                                src="../images/large.png"
                                loading="lazy"
                                height="150"
                                width="206"
                                alt=""
                                id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e745-51e295ef"
                                class="up-img"
                              />
                            </div>
                            <div
                              id="swap_product"
                              class="div-block-640 cursor-pointer"
                            >
                              <div class="div-block-591 full_chckbox">
                                <div class="div-block-669">
                                  <div class="ar-br-div">
                                    <div class="red-arrow-div">
                                      <img
                                        src="../images/redline.svg"
                                        loading="lazy"
                                        width="28"
                                        alt=""
                                        class="red-line-img"
                                      />
                                      <div class="w-embed"></div>
                                    </div>
                                  </div>
                                  <div class="addon-checkmark ds1">
                                    <input
                                      id="upgradeToKing"
                                      type="checkbox"
                                      checked={upgradeToKing}
                                      onChange={handleCheckboxChange}
                                    />
                                  </div>
                                </div>
                                <div class="checkbox-detail-2">
                                  <label for="upgradeToKing">
                                    Yes, upgrade my order for just <br />
                                    <span class="bump-title-text">
                                      $9.99 per pillow!
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="summary-heading-div-2">
                            <h1 class="heading-63">
                              <span class="text-span-125"></span> Order Summary
                            </h1>
                          </div>

                          <div class="div-block-606">
                            <div class="div-block-603">
                              <div class="div-block-574">
                                <div class="text-block-179">Nuzzle</div>
                                <div class="w-layout-grid grid-33">
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e757-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">Quantity</div>
                                    <div
                                      id="summary-qty-1"
                                      class="summary-item-2"
                                    >
                                      1
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e75c-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">Price</div>
                                    <div
                                      id="summary-qty"
                                      class="summary-item-2"
                                    >
                                      $<span id="summary-price-1">29.99</span>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e763-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">P&amp;H</div>
                                    <div
                                      id="summary-qty"
                                      class="summary-item-2"
                                    >
                                      $<span id="summary-shipping-1">8.95</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="div-block-603 nob">
                              <div class="div-block-574">
                                <div class="text-block-179">
                                  2nd Nuzzle just pay a separate fee
                                </div>
                                <div class="w-layout-grid grid-33">
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e76f-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">Quantity</div>
                                    <div
                                      id="summary-qty-2"
                                      class="summary-item-2"
                                    >
                                      1
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e774-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">Price</div>
                                    <div
                                      id="summary-qty"
                                      class="summary-item-2"
                                    >
                                      $<span id="summary-price-2">0.00</span>
                                    </div>
                                  </div>
                                  <div
                                    id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e77b-51e295ef"
                                    class="div-block-583"
                                  >
                                    <div class="text-block-165">P&amp;H</div>
                                    <div
                                      id="summary-qty"
                                      class="summary-item-2"
                                    >
                                      $<span id="summary-shipping-2">0.00</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="div-block-603 last">
                              <div class="div-block-364-copy">
                                <div class="text-block-167">Subtotal</div>
                                <div class="text-block-158">
                                  $
                                  <span
                                    id="summary-subtotal"
                                    class="text-span-124"
                                  >
                                    78.00
                                  </span>
                                </div>
                              </div>
                              <div class="div-block-364-copy-copy">
                                <div class="text-block-169">Order Total</div>
                                <div class="text-block-181">
                                  $
                                  <span
                                    id="summary-total"
                                    class="text-span-123"
                                  >
                                    48.94
                                  </span>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    data-w-id="73abaaf0-f727-8f8a-740d-753e140bd54b"
                    class="order-sum-div"
                  >
                    <div class="sum-left">
                      <div
                        id="show_summary"
                        data-w-id="73abaaf0-f727-8f8a-740d-753e140bd54d"
                        class="sum-txt-new"
                      >
                        Show order summary <span class="text-span-140"></span>
                      </div>
                      <div
                        id="hide_summary"
                        data-w-id="2c3675ea-7da5-562b-43d8-4c9ce1f916c9"
                        class="sum-txt-new"
                      >
                        Hide order summary <span class="text-span-140"></span>
                      </div>
                    </div>
                    <div class="total-left-div">
                      <div class="total-pp-price">
                        ${returnTotal.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="5de045e5-82db-828e-c7d5-b51eacf6be5d"
                    class="new-sum-box"
                  >
                    <div class="pro-select-div">
                      <div class="pro-left">
                        <div class="pro-sum-img-div">
                          <img
                            src="../images/2pil.png"
                            loading="lazy"
                            alt=""
                            class="pro-thumb-img"
                          />
                        </div>
                        <div class="pro-cent-div">
                          <div class="pro-name-sum-text">
                            Nuzzle {summaryBundle.quantity}x{" "}
                            {summaryBundle.offerType} Nuzzle Pillows
                          </div>
                        </div>
                      </div>
                      <div class="pro-right">
                        <div id="each_price_cart" class="sum-price">
                          ${summaryBundle.retailValue.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div class="sum-div">
                      <div class="sum-left">
                        <div class="save-text-sum">You Save</div>
                      </div>
                      <div class="sum-right">
                        <div class="save-price-sum">
                          ${returnSaveTotalSummay(summaryBundle).toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div class="sum-div">
                      <div class="sum-left">
                        <div class="sub-total-text">
                          Sub Total (
                          <span id="qty_item">
                            {summaryBundle.quantity} Item
                          </span>
                          )
                        </div>
                      </div>
                      <div class="sum-right">
                        <div id="sub_total_price_cart" class="sub-total-price">
                          ${returnTotalIfShippingIncluded.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div class="shpping-total-div">
                      <div class="sum-left">
                        <div class="shipping-sum-text">Shipping</div>
                      </div>
                      <div class="sum-right">
                        <div class="shipping-price">
                          {" "}
                          {summaryBundle.shippingCost === 0
                            ? "FREE SHIPPING"
                            : `$${summaryBundle.shippingCost.toFixed(2)}`}
                        </div>
                      </div>
                    </div>
                    <div
                      id="for_scrolling"
                      class="total-sum-div"
                      ref={summaryRef}
                    >
                      <div class="sum-left">
                        <div class="total-text">Total</div>
                      </div>
                      <div class="sum-right">
                        <div id="total_price_cart" class="total-sum-amount">
                          ${returnTotal.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="paypal-wrap">
                    <p class="paragraph-32">Express Checkout</p>
                    <Paypal
                      offer={summaryBundle}
                      amount={returnTotal}
                      upgradedToKing={upgradeToKing}
                    />
                  </div>
                  <div class="paypal-wrap or-wrap">
                    <p class="paragraph-32 or-txt">OR</p>
                  </div>
                  <div id="paypal_divider" class="div-block-682"></div>
                  <NMI
                    offer={summaryBundle}
                    amount={summaryBundle.afterDiscountValue}
                    upgradedToKing={upgradeToKing}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Details;
