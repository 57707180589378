function Trust() {
  return (
    <div>
      <div class="w-embed"></div>
      <section class="new-bb-sec bb-sec-2">
        <div class="w-layout-blockcontainer container-23 let1 w-container">
          <h1 class="hd-one">
            WHY 100,000+{" "}
            <span class="text-span-129">Americans TRUST NUZZLE</span>
          </h1>
          <h1 class="sec-headings-mobile-check">
            WHY 100,000+ AMERICANS TRUST NUZZLE
          </h1>
          <div class="icon-box ico-new">
            <div class="div-block-625 fe1">
              <div class="div-block-616 ddnew">
                <div class="div-block-617">
                  <img
                    src="../images/yt23.svg"
                    loading="lazy"
                    alt=""
                    class="oo-svg"
                  />
                </div>
                <div class="div-block-628">
                  <h1 class="trust-headings">90 Days Money Back Guarantee</h1>
                  <div class="text-block-186">
                    If you’re not absolutely thrilled with the pillow, we don’t
                    want your money. No hassles, no questions asked, we&#x27;ll
                    refund your money.
                  </div>
                </div>
              </div>
              <div class="div-block-616">
                <div class="div-block-617">
                  <img
                    src="../images/SVG-03.svg"
                    loading="lazy"
                    alt=""
                    class="oo-svg"
                  />
                </div>
                <div class="div-block-628">
                  <h1 class="trust-headings">Ships Within Hours</h1>
                  <div class="text-block-186">
                    Your order will ship from our dedicated warehouse in Los
                    Angeles, CA and delivered by USPS.
                  </div>
                </div>
              </div>
            </div>
            <div class="div-block-626 fe2">
              <div class="div-block-616 ddnew">
                <div class="div-block-617">
                  <img
                    src="../images/SVG-02.svg"
                    loading="lazy"
                    alt=""
                    class="oo-svg"
                  />
                </div>
                <div class="div-block-628">
                  <h1 class="trust-headings">24/7 Customer Service</h1>
                  <div class="text-block-186">
                    You can email us at{" "}
                    <a href="mailto:support@getnuzzle.com" class="link-28">
                      support@getnuzzle.com
                    </a>{" "}
                    or call us at{" "}
                    <a href="tel:+1800301-4042" class="link-29">
                      +1 (800) 301-4042
                    </a>{" "}
                    (Toll-free).
                  </div>
                </div>
              </div>
              <div class="div-block-616">
                <div class="div-block-617">
                  <img
                    src="../images/American-Owned-01.svg"
                    loading="lazy"
                    alt=""
                    class="oo-svg"
                  />
                </div>
                <div class="div-block-628">
                  <h1 class="trust-headings">American Owned and Operated</h1>
                  <div class="text-block-186">
                    Nuzzle is an American based business located at 7886 Deering
                    Ave, Canoga Park, CA 91304
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Trust;
