import io from "socket.io-client";

let socket = null;
export function initializeSocket(userId) {
  return new Promise((resolve, reject) => {
    socket = io("https://nuzzle-services-live.getnuzzle.com/", {
      query: { userId },
    });
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
      resolve(socket);
    });

    socket.on("connect_error", (err) => {
      console.log("Connetion error", err);
      reject(err);
    });

    return socket;
  });
}

export function disconnectSocket() {
  if (socket) {
    socket.disconnect();
    console.log("Socket disconnected");
  } else {
    console.warn("Socket is not initialized.");
  }
}

export function messageFromUser() {
  if (!socket || !socket.connected) {
    console.warn("Socket is not initialized or connected.");
    return;
  }

  socket.on("messageFromUser", (data) => {
    console.log("Message from user:", data.message);
  });
}

export function sendMessageToUser(message) {
  if (!socket || !socket.connected) {
    console.warn("Socket is not initialized or connected.");
    return;
  }

  const parsedMsg = JSON.stringify(message);
  socket.emit("messageToUser", parsedMsg);
}
