import React from "react";
import { useNavigate } from "react-router-dom";
import GlobalTimerStore from "store/globalTimer";
import moment from "moment";

function Header() {
  const { hours, minutes, seconds } = GlobalTimerStore();
  const navigate = useNavigate();

  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };

  const deliverDate = addBusinessDays(moment(), 1).format("ddd, MMM Do");

  const onOrder = () => {
    navigate("/checkout");
  };

  return (
    <div>
      <div class="sticky-nav">
        <a class="button-sm-2 sticky-btn-mb cta_btn w-button">buy now</a>
        <div class="sticky-nav-div">
          <div class="sticky-nav-item-new">
            <img
              src="images/fire.svg"
              loading="lazy"
              alt=""
              class="gray-icon-2"
            />
            <div class="rem-copy">
              Hurry! <span class="greens-span">126 people</span> are on checkout
            </div>
          </div>
          <div class="sticky-nav-item-new">
            <img
              src="images/Green-circle.svg"
              loading="lazy"
              alt=""
              class="circle-green"
            />
            <div class="ship-by-copy">
              Ships by{" "}
              <span id="date-1" class="date-1-2 date-1 timer">
                {deliverDate}
              </span>
            </div>
            <a
              class="button-sm-2 sticky-btn cta_btn w-button"
              onClick={onOrder}
            >
              ORDER now
            </a>
          </div>
        </div>
        <div class="w-embed"></div>
      </div>
      <div class="navbar-countdown gradient-holiday">
        <div class="holiday-last-chance">
          <div class="navbar-sale-inner">
            <div class="new-navbar-containers is-black">
              <span id="listicle_sale_name" class="holiday-banners">
                <strong>BLACK FRIDAY SALE!</strong>
              </span>
            </div>
            <div class="text-block-209">
              Up to 60% PLUS Get FREE Shipping On All Bundles
              <br />
            </div>
          </div>
          <div class="div-block-874">
            <div id="js-clock" class="holiday-clock">
              <div class="box-2 hide">
                <div id="js-clock-days" class="clock-number-2">
                  00
                </div>
                <div class="clock-label-2">Days</div>
              </div>
              <div class="text-block-210 hide">:</div>
              <div class="box-2">
                <div id="js-clock-hours" class="clock-number-2">
                  {hours}
                </div>
                <div class="clock-label-2">Hrs</div>
              </div>
              <div class="text-block-210">:</div>
              <div class="box-2">
                <div id="js-clock-minutes" class="clock-number-2">
                  {minutes}
                </div>
                <div class="clock-label-2">Min</div>
              </div>
              <div class="text-block-210">:</div>
              <div class="box-2">
                <div id="js-clock-seconds" class="clock-number-2">
                  {seconds}
                </div>
                <div class="clock-label-2">Sec</div>
              </div>
              <div class="hide-2 w-embed w-script"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="stick-nav-mobile">
        <a class="button-sm-3 sticky-btn-mb cta_btn w-button" onClick={onOrder}>
          YES! I WANT 50% OFF
        </a>
        <div class="sticky-menu-div">
          <div class="ss-nav-1">
            <img
              src="images/fire.svg"
              loading="lazy"
              alt=""
              class="gray-icon-2"
            />
            <div class="rem-copy">
              Hurry! <span class="text-span-147">126 people</span> are on
              checkout
            </div>
          </div>
          <div class="ss-nav-2">
            <img
              src="images/Green-circle.svg"
              loading="lazy"
              alt=""
              class="circle-green"
            />
            <div class="ship-by-copy">
              Ships by <span class="date-1">{deliverDate}</span>
            </div>
            <a
              class="button-sm-3 sticky-btn cta_btn w-button"
              onClick={onOrder}
            >
              ORDER now
            </a>
          </div>
        </div>
      </div>
      <div class="top-bar-note-3 np-hide">
        <div class="select-field-styles w-embed"></div>
        <div class="top-note-down-div">
          <div>
            <div class="top-bar-text">
              <a
                href="http://offer.getnuzzle.com/checkout/secure-v03-ca-wip?forceVariantId=42529119797424"
                class="link-17"
              >
                FAST SHIPPING FREE FROM UTAH
              </a>
            </div>
          </div>
          <div class="div-block-557">
            <img
              src="images/sdes.svg.png"
              loading="lazy"
              sizes="100vw"
              srcset="
            images/sdes.svg-p-500.png  500w,
            images/sdes.svg-p-800.png  800w,
            images/sdes.svg.png       1024w
          "
              alt=""
              class="image-112"
            />
          </div>
        </div>
      </div>
      <div class="top-navbar-2">
        <div class="mob-top-nav-div">
          <img
            src="images/Nuzzle-Logo.svg"
            loading="lazy"
            alt=""
            class="mob-logo-top-img"
          />
        </div>
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          class="navbar-4 yp1 w-nav"
        >
          <div class="nav-con-2 is-centered w-container">
            <a href="#" class="w-nav-brand">
              <img
                src="images/Nuzzle-Logo.svg"
                loading="lazy"
                width="132"
                alt="Logo-Image"
                class="logo-image"
              />
              <img
                src="images/logo.png"
                loading="lazy"
                width="111.5"
                alt="Logo-Image"
                class="logo-image formobilenew v3"
              />
            </a>
            <nav role="navigation" class="nav-menu-2 is-removed w-nav-menu">
              <a class="nav-btn-div-2 mobnow cta_btn change1 w-nav-link">
                UNLOCK OFFER
              </a>
            </nav>
            <a class="mob-btn-div-2 cta_btn v03mobile w-inline-block">
              <div class="text-block-92-copy">ORDER NOW</div>
            </a>
          </div>
        </div>
      </div>
      <section class="new-hero-section">
        <div class="main-hero-wrapper">
          <div class="he-left-div">
            <div class="new-badges-block">
              <img src="images/badges-long.png" loading="lazy" alt="" />
            </div>
            <div class="hero-image-block">
              <img
                src="images/hero_v06.jpg"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, 55vw"
                srcset="
              images/hero_v06-p-500.jpg   500w,
              images/hero_v06-p-800.jpg   800w,
              images/hero_v06-p-1080.jpg 1080w,
              images/hero_v06-p-1600.jpg 1600w,
              images/hero_v06.jpg        1718w
            "
                alt=""
                class="image-142"
              />
            </div>
          </div>
          <div class="he-right-div">
            <div class="he-right-inner">
              <div class="hgt-div">
                <div class="top-star-block">
                  <div class="div-block-877">
                    <img
                      src="images/Trustpilot12.png"
                      loading="lazy"
                      alt=""
                      class="yp-img"
                    />
                    <img
                      src="images/Trustpilot_Stars.svg"
                      loading="lazy"
                      alt=""
                      class="yp-desk-img"
                    />
                  </div>
                  <div class="ex-div1">
                    <div class="dr-txt is-black">
                      Excellent | 10,563 reviews
                    </div>
                  </div>
                </div>
                <h1 class="top-hero-heading dr3 is-black">
                  Stop Your Neck Pain... FAST
                </h1>
              </div>
              <div class="noti-div">
                <div class="div-block-690">
                  <img
                    src="images/us-flag-circle.png"
                    loading="lazy"
                    alt=""
                    class="us-flag-circle-img"
                  />
                </div>
                <div>
                  <div class="noti-txt">
                    America&#x27;s #1 Voted Pillow in 2023
                  </div>
                </div>
              </div>
              <div class="hero-features-block dr3 newlp3">
                <div class="ff-txt-div">
                  <div class="ff-top-text is-black">
                    Transforming American Lives: The Nuzzle pillow delivers neck
                    pain sufferers real relief &amp; energy filled mornings -
                    for good!
                  </div>
                </div>
                <div class="half-div"></div>
                <div class="feature-block-new">
                  <div class="fea-block-2">
                    <div class="ico-div-block toptab we1">
                      <img
                        src="images/green-check.svg"
                        loading="lazy"
                        alt=""
                        class="ccmark-svg"
                      />
                    </div>
                    <div class="point-div toptab ds12">
                      <div class="hero-point-text tt1 is-black">
                        Works for Back, Side, and Stomach Sleepers
                      </div>
                    </div>
                  </div>
                </div>
                <div class="feature-block-new">
                  <div class="fea-block-2">
                    <div class="ico-div-block toptab we1">
                      <img
                        src="images/green-check.svg"
                        loading="lazy"
                        alt=""
                        class="ccmark-svg"
                      />
                    </div>
                    <div class="point-div toptab ds12">
                      <div class="hero-point-text tt1 is-black">
                        Supports the natural curvature
                      </div>
                    </div>
                  </div>
                </div>
                <div class="feature-block-new">
                  <div class="fea-block-2">
                    <div class="ico-div-block toptab we1">
                      <img
                        src="images/green-check.svg"
                        loading="lazy"
                        alt=""
                        class="ccmark-svg"
                      />
                    </div>
                    <div class="point-div toptab ds12">
                      <div class="hero-point-text tt1 is-black">
                        Naturally improves sleep quality
                      </div>
                    </div>
                  </div>
                </div>
                <div class="feature-block-new">
                  <div class="fea-block-2">
                    <div class="ico-div-block toptab we1">
                      <img
                        src="images/green-check.svg"
                        loading="lazy"
                        alt=""
                        class="ccmark-svg"
                      />
                    </div>
                    <div class="point-div toptab ds12">
                      <div class="hero-point-text tt1 is-black">
                        Now on the BIGGEST DISCOUNT UP TO 60% OFF!
                      </div>
                    </div>
                  </div>
                </div>
                <div class="hero-button-block cursor-pointer">
                  <a
                    class="new-hero-btn cta_btn new-pp-cta w-inline-block"
                    onClick={onOrder}
                  >
                    <div class="div-block-689">
                      <div>
                        <div class="text-block-151">YES! I WANT 50% OFF</div>
                      </div>
                      <div>
                        <img
                          src="images/a34.svg"
                          loading="lazy"
                          width="153.5"
                          alt=""
                          class="image-109"
                        />
                      </div>
                    </div>
                    <div class="ct-btn-div">
                      <img
                        src="images/hourglass_white.svg"
                        loading="lazy"
                        alt=""
                        class="gray-icon-2 hourglass on-cta"
                      />
                      <div class="ct-btn-txt">Almost Sold Out</div>
                    </div>
                  </a>
                  <div class="top-bb-div">
                    <div>
                      <img
                        src="images/cc-card-img.svg"
                        loading="lazy"
                        width="168"
                        alt=""
                        class="cc-cards-img"
                      />
                    </div>
                  </div>
                </div>
                <div class="change-season-div">
                  <div class="two-ss-div">
                    <div class="off-div-2">
                      <div class="off-txt">Fast Shipping from Los Angeles</div>
                    </div>
                  </div>
                </div>
                <div class="but-div">
                  <div class="dr-1-div">
                    <div class="cs-dd-div">
                      <img
                        src="images/shipping-fast.svg"
                        loading="lazy"
                        alt=""
                        class="gt-svg"
                      />
                    </div>
                    <div>
                      <div class="se-rr-txt">Express US Shipping</div>
                    </div>
                  </div>
                  <div class="dr-2-div">
                    <div class="cs-dd-div">
                      <img
                        src="images/money-back.svg"
                        loading="lazy"
                        alt=""
                        class="gt-svg"
                      />
                    </div>
                    <div>
                      <div class="se-rr-txt">90 Day Money Back Guarantee</div>
                    </div>
                  </div>
                </div>

                <div className="HomeAccordion">
                  <div class="Option">
                    <input class="Toggle" id="Accordion_11" type="checkbox" />
                    <label class="Title" for="Accordion_11">
                      Shipping Details
                    </label>
                    <div className="Body">
                      <h4>
                        <b>Delivery:</b> Receive by {deliverDate}. Order within{" "}
                        <span>3 hrs 6 mins</span>
                      </h4>
                      <h4>
                        <b>Courier Company:</b>{" "}
                        <img src="img/courier.svg" alt="Courier" /> UNITED
                        STATES POSTAL SERVICE‍
                      </h4>
                      <p>
                        All orders are shipped with express service and include
                        a tracking number.
                      </p>
                      <p>
                        We ensure that your package will reach you safely and on
                        time. If there are any problems during transit, we will
                        work to solve the problems and provide the best
                        solutions.
                      </p>
                    </div>
                  </div>
                  <div class="Option">
                    <input class="Toggle" id="Accordion_22" type="checkbox" />
                    <label class="Title" for="Accordion_22">
                      Nuzzle's Guarantee
                    </label>
                    <div className="Body">
                      <ul>
                        <li>
                          <img src="img/free_returns.svg" alt="Free" />{" "}
                          <span>Free returns within 90 days</span>
                        </li>
                        <li>
                          <img src="img/check.svg" alt="Check" />{" "}
                          <p>
                            Any item(s) are eligible for a full refund within 90
                            days of purchase.
                          </p>
                        </li>
                        <li>
                          <img src="img/check.svg" alt="Check" />{" "}
                          <p>
                            Email us at support@getnuzzle.com for quality
                            US-based support.
                          </p>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <img src="img/shop_verified.svg" alt="Shop" />{" "}
                          <span>Shop confidently with our secure systems</span>
                        </li>
                        <li>
                          <img src="img/check.svg" alt="Check" />{" "}
                          <p>Card information is secure and uncompromised</p>
                        </li>
                        <li>
                          <img src="img/check.svg" alt="Check" />{" "}
                          <p>
                            We keep your personal data private, safe, and secure
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div class="faq_component">
                  <div class="faq_accordion">
                    <div
                      js-faq-collapse="true"
                      data-w-id="199e4e93-9e63-3552-14b8-4c3d37acb652"
                      class="faq_question"
                    >
                      <div class="text-size-medium text-weight-bold">
                        Shipping Details
                      </div>
                      <div class="faq_icon-wrapper">
                        <div class="icon-embed-small w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewbox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ width: "100%", height: "0px" }}
                      class="faq_answer"
                    >
                      <div class="margin-bottom margin-small">
                        <p class="dd-top-con-para">
                          <strong>Delivery: </strong>Receive by
                          <span class="ship_txt">Feb 16th</span>. Order within
                          <span class="time-order-within">3 hrs 6 mins</span>
                        </p>
                        <p class="dd-top-con-para">
                          <strong>Courier Company:</strong>
                          <span class="ico-txt-bet">U</span> UNITED STATES
                          POSTAL SERVICE‍
                          <br />
                        </p>
                        <p class="dd-top-con-para">
                          All orders are shipped with express service and
                          include a tracking number.
                        </p>
                        <p class="dd-top-con-para">
                          We ensure that your package will reach you safely and
                          on time. If there are any problems during transit, we
                          will work to solve the problems and provide the best
                          solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="faq_accordion">
                    <div
                      js-faq-collapse="true"
                      data-w-id="199e4e93-9e63-3552-14b8-4c3d37acb671"
                      class="faq_question"
                    >
                      <div class="text-size-medium text-weight-bold">
                        Nuzzle&#x27;s Guarantee
                      </div>
                      <div class="faq_icon-wrapper">
                        <div class="icon-embed-small w-embed">
                          <svg
                            width="100%"
                            height="100%"
                            viewbox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16.5303 20.8839C16.2374 21.1768 15.7626 21.1768 15.4697 20.8839L7.82318 13.2374C7.53029 12.9445 7.53029 12.4697 7.82318 12.1768L8.17674 11.8232C8.46963 11.5303 8.9445 11.5303 9.2374 11.8232L16 18.5858L22.7626 11.8232C23.0555 11.5303 23.5303 11.5303 23.8232 11.8232L24.1768 12.1768C24.4697 12.4697 24.4697 12.9445 24.1768 13.2374L16.5303 20.8839Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ width: "100%", height: "0px" }}
                      class="faq_answer"
                    >
                      <div class="margin-bottom margin-small">
                        <div class="tab-wrapper-div">
                          <div class="tab-sub-div">
                            <div>
                              <img
                                src="images/pack-return.svg"
                                loading="lazy"
                                alt=""
                                class="secure-dvg"
                              />
                            </div>
                            <div>
                              <div class="sub-text-one">
                                Free returns within 90 days
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="tab-down-div">
                            <div>
                              <img
                                src="images/tick1.svg"
                                loading="lazy"
                                alt=""
                                class="top-tick-svg"
                              />
                            </div>
                            <div>
                              <div class="text-block-211">
                                Any item(s) are eligible for a full refund
                                within 90 days of purchase.
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="tab-down-div">
                            <div>
                              <img
                                src="images/tick1.svg"
                                loading="lazy"
                                alt=""
                                class="top-tick-svg"
                              />
                            </div>
                            <div>
                              <div class="text-block-211">
                                Email us at support@getnuzzle.com for quality
                                US-based support.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-wrapper-div">
                          <div class="tab-sub-div">
                            <div>
                              <img
                                src="images/shield-secure.svg"
                                loading="lazy"
                                alt=""
                                class="secure-dvg"
                              />
                            </div>
                            <div>
                              <div class="sub-text-one">
                                Shop confidently with our secure systems <br />
                              </div>
                            </div>
                          </div>
                          <div class="tab-down-div">
                            <div>
                              <img
                                src="images/tick1.svg"
                                loading="lazy"
                                alt=""
                                class="top-tick-svg"
                              />
                            </div>
                            <div>
                              <div class="text-block-211">
                                Card information is secure and uncompromised
                                <br />
                              </div>
                            </div>
                          </div>
                          <div class="tab-down-div">
                            <div>
                              <img
                                src="images/tick1.svg"
                                loading="lazy"
                                alt=""
                                class="top-tick-svg"
                              />
                            </div>
                            <div>
                              <div class="text-block-211">
                                We keep your personal data private, safe, and
                                secure
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
