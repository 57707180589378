import React, { useEffect, useMemo, useState } from "react";
import mainStore from "store/store";
import { useNavigate } from "react-router-dom";
import { trackPageViewEvent } from "mixpanel/events";

function ThankYou() {
  const navigate = useNavigate();
  const { paymentIntent, setOrderPlaced } = mainStore();
  const [orderDetails, setOrderDetails] = useState({
    details: { offer: {}, items: [], customer: {} },
    gateway: "",
    amount: 0,
  });

  useEffect(() => {
    if (!paymentIntent) {
      navigate("/");
    }
    trackPageViewEvent({ page_type: "Thank You" });

    setOrderPlaced(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  useEffect(() => {
    if (paymentIntent) {
      setOrderDetails(paymentIntent);
    }
  }, [paymentIntent]);

  const returnSaveTotalSummay = useMemo(() => {
    const bundle = orderDetails.details.offer;
    const upgradeToKing = orderDetails.details.upgradedToKing;
    if (!bundle) {
      return 0;
    }
    let amount = 0;
    // if (upgradeToKing) {
    //   amount = bundle.retailValue - (bundle.afterDiscountValue + upgradeAmount);
    // }
    amount = bundle.retailValue - bundle.afterDiscountValue;

    return amount.toFixed(2);
  }, [orderDetails]);

  const returnTotal = useMemo(() => {
    const items = orderDetails.details.items;

    let amount = items.reduce((acc, item) => {
      return acc + item.price;
    }, 0);

    amount = amount + orderDetails.details.offer.shippingCost;

    return amount.toFixed(2);
  }, [orderDetails]);

  return (
    <>
      <div className="container Thankyou">
        <div className="Left">
          <div className="Header">
            <img src="/img/logo_4.png" alt="Logo" />
          </div>
          <div className="Tag">
            <span>
              Exclusive Offer
              <img src="img/chev.svg" alt="Chev" />
            </span>
            <span>
              Checkout
              <img src="img/chev.svg" alt="Chev" />
            </span>
            <span>
              Order Receipt
              <img src="img/chev.svg" alt="Chev" />
            </span>
          </div>
          <div className="Message">
            <h1>Thank You! ✨</h1>
            <p>You're one step closer to upgrading your morning ritual.</p>
          </div>
          <div className="Update">
            <h2>Your order is confirmed</h2>
            <p>
              <b>We've received your order and we're getting it ready!</b> You
              should receive a follow up email within the next 15 minutes with
              all of the details.
            </p>
          </div>
          <div className="Update">
            <h2>Order updates</h2>
            <p>You’ll get shipping and delivery updates by email.</p>
          </div>
          <div className="Update Information">
            <h2>Customer Information</h2>
            <div>
              <div>
                <b>Billing Address</b>
              </div>
              <table>
                <tr>
                  <th>Address: </th>
                  <th>Phone:</th>
                  <th>Postal Code:</th>
                  <th>Email:</th>
                </tr>
                <tr>
                  <td>{`${orderDetails.details.customer.line1}, ${orderDetails.details.customer.line2}, ${orderDetails.details.customer.state} ${orderDetails.details.customer.city}, ${orderDetails.details.customer.country}`}</td>
                  <td>{orderDetails.details.customer.phone}</td>
                  <td>{orderDetails.details.customer.postal_code}</td>
                  <td>{orderDetails.details.customer.email}</td>
                </tr>
              </table>
            </div>
            {/* <div>
              <div>
                <b>Shipping Address</b>
              </div>
              <table>
                <tr>
                  <th>Address: </th>
                  <th>Phone:</th>
                  <th>Postal Code:</th>
                  <th>Email:</th>
                </tr>
                <tr>
                  <td>250-E Pia, Na, Lahore, Pakistan</td>
                  <td>+92 3245 2432453</td>
                  <td>456341</td>
                  <td>naqsshali@gmai.com</td>
                </tr>
              </table>
            </div> */}
          </div>
        </div>
        <div className="Right">
          <h1>ORDER SUMMARY</h1>
          <div className="Heading">
            <h2>Order Summary</h2>
            {/* <span>
              $
              {orderDetails.gateway === "stripe"
                ? (orderDetails.amount / 100).toFixed(2)
                : orderDetails.amount.toFixed(2)}
            </span> */}
          </div>
          <div className="Content">
            <div className="Image">
              <div className="Price">
                <img src="img/pillow_price1.png" alt="Pillow" />
                <p>
                  Nuzzle {orderDetails.details.offer.quantity}x{" "}
                  {orderDetails.details.offer.offerType} Nuzzle Pillows
                </p>
              </div>
              <span>${orderDetails.details.offer.retailValue?.toFixed(2)}</span>
            </div>
            <ul className="Value">
              <li>
                <span>You Save</span>
                <span>${returnSaveTotalSummay}</span>
              </li>
              <li>
                <span>Total {orderDetails.details.items.length} item(s)</span>
              </li>
              {orderDetails.details.items.map((item, index) => (
                <li key={index}>
                  <span>{item.title}</span>
                  <span>${item.price.toFixed(2)}</span>
                </li>
              ))}
              <li>
                <span>Shipping</span>
                <span>
                  {orderDetails.details.offer.shippingCost === 0
                    ? "FREE SHIPPING"
                    : `$${orderDetails.details.offer.shippingCost?.toFixed(2)}`}
                </span>
              </li>
              <li>
                <span>Total</span>
                <span>${returnTotal}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
