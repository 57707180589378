import { useNavigate } from "react-router-dom";

function DetailsHeaader() {
  const navigate = useNavigate();
  const onOrder = () => {
    navigate("/");
  };

  return (
    <div>
      <div class="top-div-new fd1">
        <div class="top-header-div-block desktop newdesk">
          <div class="top-banner-logo-div cursor-pointer" onClick={onOrder}>
            <img
              src="../images/Nuzzle-Logo.svg"
              alt=""
              class="brand-logo-image-3 hh1 fd1"
            />
            <div class="ss-block">
              <div class="ss-text-top ds1">SECURE CHECKOUT</div>
            </div>
          </div>
          <div class="top-banner-badge-div">
            <div class="pp-image-div">
              <img
                src="../images/phone-call-1.svg"
                loading="lazy"
                alt=""
                class="phone-img"
              />
            </div>
            <div class="bb_phone">
              <div class="call-num-top-text ca1">
                Questions?
                <br />
                Call: <span>(800) 301-4042</span>
              </div>
            </div>
          </div>
        </div>
        <div class="top-header-new-mobile">
          <div class="top-banner-logo-div" onClick={onOrder}>
            <img
              src="../images/Nuzzle-Logo.svg"
              alt=""
              class="brand-logo-image-3 hh1 fv1"
            />
            <div class="ss-block">
              <div class="ss-text-top ds1">SECURE CHECKOUT</div>
            </div>
          </div>
          <div>
            <div class="top-banner-badge-div">
              <div class="pp-image-div">
                <img
                  src="../images/phone-call-1.svg"
                  loading="lazy"
                  alt=""
                  class="pp-img"
                />
              </div>
              <div class="bb_phone">
                <div class="call-num-top-text">
                  Questions?
                  <br />
                  Call: <span>(800) 301-4042</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsHeaader;
