import { create } from "zustand";
import axiosInstance from "axios-interceptor/axios";

const shopifyStore = create(() => ({
  createShopifyOrder: async (data) => {
    try {
      const res = await axiosInstance.post("/shopify/createOrder", data);

      if (res?.data && !res.data.error) {
        return res.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  },

  createProduct: async (data) => {
    try {
      const res = await axiosInstance.post("/shopify/createProduct", data);

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getAllOrders: async () => {
    try {
      const res = await axiosInstance.get("/shopify/getAllOrders");

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  getAllProducts: async () => {
    try {
      const res = await axiosInstance.get("/shopify/getAllProducts");

      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  },

  refundPayment: async (data) => {
    try {
      const res = await axiosInstance.post("/shopify/refundOrder", data);
      if (res?.refund) {
        return res.refund;
      }
    } catch (error) {
      return null;
    }
  },
}));

export default shopifyStore;
