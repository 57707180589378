export function validateNMIForm(values, isBillingAddress) {
  const errors = {};
  if (!values.phone) {
    errors.phone = "Required";
  }
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (
    !/^[A-Za-z0-9._%+-]+[A-Za-z0-9%+-]@[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/.test(
      values.email
    )
  ) {
    errors.email = "Invalid email address";
  }
  if (!values.country) {
    errors.country = "Required";
  }
  if (!values.line1) {
    errors.line1 = "Required";
  }
  if (!values.city) {
    errors.city = "Required";
  }
  if (!values.state) {
    errors.state = "Required";
  }
  if (!values.postal_code) {
    errors.postal_code = "Required";
  }

  if (!isBillingAddress) {
    errors.billingAddress = {};

    if (!values.billingAddress.firstName) {
      errors.billingAddress.firstName = "Required";
    }
    if (!values.billingAddress.lastName) {
      errors.billingAddress.lastName = "Required";
    }

    if (!values.billingAddress.country) {
      errors.billingAddress.country = "Required";
    }
    if (!values.billingAddress.line1) {
      errors.billingAddress.line1 = "Required";
    }
    if (!values.billingAddress.city) {
      errors.billingAddress.city = "Required";
    }
    if (!values.billingAddress.state) {
      errors.billingAddress.state = "Required";
    }
    if (!values.billingAddress.postal_code) {
      errors.billingAddress.postal_code = "Required";
    }
  }

  return errors;
}
