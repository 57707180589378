import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import mainStore from "store/store";
import PulseLoader from "react-spinners/PulseLoader";
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { validateNMIForm } from "utils/validations/nmi";
import valid from "card-validator";
import InputMask from "react-input-mask";
import moment from "moment";
import PurchaseStore from "store/purchase";
import { encrypt } from "utils/encryption";
import { getDeviceName } from "utils/global";

function NMI({ offer, amount, upgradedToKing }) {
  const navigate = useNavigate();

  const {
    setPaymentIntent,
    meta,
    startCountdown,
    startTimer,
    connectionId,
    taboolaClickId,
    voluumCid,
    uniqueUserId,
    utmParams,
  } = mainStore();
  const { managePurchase } = PurchaseStore();

  const formRef = useRef(null);
  const billingFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isBillingAddress, setIsBillingAddress] = useState(true);

  const libraries = ["places"];
  const [autocomplete, setAutocomplete] = useState(null);
  const [autocompleteBilling, setAutocompleteBilling] = useState(null);
  const [errors, setErrors] = useState({
    cardNumber: null,
    expirationDate: null,
    cvv: null,
  });
  const [cardType, setCardType] = useState(null);

  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");

  const initialValues = {
    phone: "",
    firstName: "",
    lastName: "",
    email: "",
    country: "US",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    billingAddress: {
      firstName: "",
      lastName: "",
      country: "US",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
    },
  };

  const resetBillingAddress = (formik, reset) => {
    const billingAddress = {
      firstName: "",
      lastName: "",
      email: "",
      country: "US",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
    };

    if (reset) {
      formik.values.billingAddress = billingAddress;
    }
  };

  const addBusinessDays = (startDate, daysToAdd) => {
    let currentDate = moment(startDate);
    let addedDays = 0;

    while (addedDays < daysToAdd) {
      currentDate.add(1, "days");
      if (currentDate.isoWeekday() <= 5) {
        addedDays++;
      }
    }
    return currentDate;
  };

  const deliverDate = addBusinessDays(moment(), 1).format("dddd, MMM Do");

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onLoadBilling = (autocompleteInstance) => {
    setAutocompleteBilling(autocompleteInstance);
  };

  const onPlaceChanged = (formik) => {
    if (autocomplete) {
      const place = autocomplete.getPlace();

      const addressComponents = place.address_components;

      if (addressComponents) {
        const streetNumber = addressComponents.find((component) =>
          component.types.includes("street_number")
        );

        const streetAddress = addressComponents.find((component) =>
          component.types.includes("route")
        );

        const cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );

        const stateComponent = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        );

        const postalCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );

        if (streetAddress) {
          formik.values.line1 = streetNumber
            ? `${streetNumber.short_name} ${streetAddress.long_name}`
            : streetAddress.long_name;
        }

        if (cityComponent) {
          formik.values.city = cityComponent.short_name;
        }

        if (stateComponent) {
          formik.values.state = stateComponent.short_name;
        }

        if (postalCodeComponent) {
          formik.values.postal_code = postalCodeComponent.short_name;
        }
      }
      formik.validateForm();
    }
  };

  const onPlaceChangedBilling = (formik) => {
    if (autocompleteBilling) {
      const place = autocompleteBilling.getPlace();
      formik.values.billingAddress.line1 = place.formatted_address;

      const addressComponents = place.address_components;

      if (addressComponents) {
        const streetNumber = addressComponents.find((component) =>
          component.types.includes("street_number")
        );

        const streetAddress = addressComponents.find((component) =>
          component.types.includes("route")
        );

        const cityComponent = addressComponents.find((component) =>
          component.types.includes("locality")
        );

        const stateComponent = addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        );

        const postalCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );

        if (streetAddress) {
          formik.values.billingAddress.line1 = streetNumber
            ? `${streetNumber.short_name} ${streetAddress.long_name}`
            : streetAddress.long_name;
        }

        if (cityComponent) {
          formik.values.billingAddress.city = cityComponent.short_name;
        }

        if (stateComponent) {
          formik.values.billingAddress.state = stateComponent.short_name;
        }

        if (postalCodeComponent) {
          formik.values.billingAddress.postal_code =
            postalCodeComponent.short_name;
        }
      }
      formik.validateForm();
    }
  };

  const formatCardNumber = (value) => {
    return value
      .replace(/\s+/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  const validateCardNumber = (number) => {
    const validation = valid.number(number);
    setCardType(validation.card ? validation.card.type : null);

    if (!validation.isPotentiallyValid || !validation.isValid) {
      setErrors((prev) => ({ ...prev, cardNumber: true }));
    } else {
      setErrors((prev) => ({ ...prev, cardNumber: false }));
    }
  };

  const validateExpirationDate = (date) => {
    const validation = valid.expirationDate(date);
    if (!validation.isValid) {
      setErrors((prev) => ({
        ...prev,
        expirationDate: true,
      }));
    } else {
      setErrors((prev) => ({ ...prev, expirationDate: false }));
    }
  };

  const validateCvv = (cvv) => {
    const validation = valid.cvv(cvv);
    console.log("validateCvv ~ validation:", validation);
    if (!validation.isValid) {
      setErrors((prev) => ({ ...prev, cvv: true }));
    } else {
      setErrors((prev) => ({ ...prev, cvv: false }));
    }
  };

  const handleCardNumberChange = (e) => {
    const number = e.target.value;
    const formattedNumber = formatCardNumber(number);
    setCardNumber(formattedNumber);
    validateCardNumber(formattedNumber);
  };

  const handleExpirationDateChange = (e) => {
    const date = e.target.value;
    setExpirationDate(date);
    validateExpirationDate(date);
  };

  const handleCvvChange = (e) => {
    const cvv = e.target.value;
    setCvv(cvv);
    setErrors({ ...errors, cvv: cvv && cvv != "" ? false : null });
    // validateCvv(cvv);
  };

  const handleSubmit = async (data) => {
    let customer = { ...data };
    if (isBillingAddress) {
      const cOnly = { ...customer };
      if (cOnly.billingAddress) {
        delete cOnly.billingAddress;
      }
      customer = { ...customer, billingAddress: cOnly };
    }

    const paymentDetails = {
      ccnumber: cardNumber.replaceAll(" ", ""),
      ccexp: expirationDate.replace("/", ""),
      cvv: cvv,
    };

    const encryptedData = encrypt(paymentDetails);

    const payload = {
      socketId: connectionId,
      gateway: "nmi",
      step: "step-1",
      userEmail: customer.email,
      isOrderCreated: false,
      taboolaClickId: taboolaClickId,
      voluumCid: voluumCid,
      uniqueUserId: uniqueUserId,
      paymentDetails: encryptedData,
      eventDetails: {
        url: window.location?.href,
        device: getDeviceName(),
        utm_campaign: utmParams.utm_campaign,
        utm_medium: utmParams.utm_medium,
        utm_source: utmParams.utm_source,
      },
      details: {
        customer: customer,
        upgradedToKing: upgradedToKing,
        currency: "usd",
        offer: offer,
        items: [
          {
            title: offer.title,
            price: amount,
            quantity: 1,
            sku: offer.sku,
            product_id: offer.productId,
          },
        ],
      },
    };

    setLoading(true);
    const res = await managePurchase(payload);
    console.log("handleSubmit ~ res:", res);

    if (res?.success) {
      setPaymentIntent(res.data);
      setLoading(false);
      startCountdown();
      startTimer();
      if (upgradedToKing) {
        navigate("/up-sell-1b");
      } else {
        navigate("/up-sell-1a");
      }
    } else {
      alert(res?.errorMessage);
      setLoading(false);
    }
  };

  const handleScroll = async (formik) => {
    const validateForm = await formik.validateForm();

    if (
      validateForm.billingAddress &&
      !Object.keys(validateForm.billingAddress).length
    ) {
      delete validateForm.billingAddress;
    }

    if (
      validateForm.billingAddress &&
      Object.keys(validateForm.billingAddress).length
    ) {
      billingFormRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (Object.keys(validateForm).length) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (
      errors.cardNumber === null ||
      errors.cvv === null ||
      errors.expirationDate === null ||
      errors.cardNumber ||
      errors.cvv ||
      errors.expirationDate
    ) {
      const cardError = {
        cardNumber: null,
        expirationDate: null,
        cvv: null,
      };
      if (errors.cardNumber === null) {
        cardError.cardNumber = true;
      }
      if (errors.expirationDate === null) {
        cardError.expirationDate = true;
      }
      if (errors.cvv === null) {
        cardError.cvv = true;
      }
      billingFormRef.current.scrollIntoView({ behavior: "smooth" });
      setErrors({ ...errors, ...cardError });
      console.log("errors", errors);
    } else {
      handleSubmit(formik.values);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => validateNMIForm(values, isBillingAddress)}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <div>
            <div id="takeone" class="new_second checkdown vsf ee1">
              <div
                id="w-node-_65403f5f-0e5f-8cec-9204-b5b31e30e7b4-733aae88"
                class="div-block-593 topnewcheck tr1"
              >
                <div class="div-block-596 s5">
                  <div class="outer_div_one-2 paypal-btn">
                    <div class="text_div_outer-2">
                      <div class="text-block-175">Express Checkout</div>
                    </div>
                    <a
                      href="#"
                      data-w-id="65403f5f-0e5f-8cec-9204-b5b31e30e7d2"
                      class="pp_div_new_link payment-paypal w-inline-block"
                    >
                      <img
                        src="../images/paypal-icon.svg"
                        loading="lazy"
                        width="112"
                        alt=""
                      />
                    </a>
                  </div>
                  <div id="ex_credit_bluesnap" class="expand_div-2">
                    <div id="main_form" class="rtc-main-form-2 is-active">
                      <div class="form-wrapper w-form">
                        <form
                          method="post"
                          data-name=""
                          id="wf-form-"
                          name="wf-form-"
                          class="form-2 checkout-combo-form"
                          data-wf-page-id="65722a0f00e541bf733aae88"
                          data-wf-element-id="65403f5f-0e5f-8cec-9204-b5b31e30e842"
                        >
                          <div class="form-content">
                            <div class="rtc-combo-form">
                              <Form ref={formRef}>
                                <div class="payment-flds-box toprev fwr contact_bluesnap">
                                  <h4 class="ss-info-heading">Contact</h4>
                                  <div id="overlay" class="rtc-form-container">
                                    <Field
                                      id="email"
                                      name="email"
                                      type="email"
                                      className="input-flds-2 required re1 w-input"
                                      placeholder="Email*"
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      style={{
                                        borderColor: formik.errors.email
                                          ? "red"
                                          : "",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div class="payment-flds-box toprev kh delivery_bluesnap">
                                  <h4 class="ss-info-heading">Delivery</h4>
                                  <div id="overlay" class="rtc-form-container">
                                    <div class="rtc-grid">
                                      <div class="form-group half-fld fde">
                                        <div class="frm-flds">
                                          <div class="select-form-wrapper">
                                            <label
                                              for="shipping_country_bluesnap"
                                              class="input-label float"
                                            >
                                              Country
                                            </label>
                                            <select
                                              className="input-flds-2 select-field required w-select"
                                              id="country"
                                              name="country"
                                              value={formik.values.country}
                                              onChange={formik.handleChange}
                                            >
                                              <option value="US">
                                                United States
                                              </option>
                                            </select>
                                            <div class="caret-down">
                                              <img
                                                alt=""
                                                loading="lazy"
                                                src="https://uploads-ssl.webflow.com/6354b2a076a5d651155d856e/6354b2a076a5d62c5a5d8586_select-checkout.svg"
                                                class="image-123"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="rtc-grid">
                                      <div class="form-group half-fld">
                                        <div class="frm-flds">
                                          <label
                                            for="first_name_bluesnap"
                                            class="input-label"
                                          >
                                            First name *
                                          </label>
                                          <Field
                                            type="text"
                                            className="input-flds-2 required w-input"
                                            placeholder="First Name*"
                                            id="firstName"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            style={{
                                              borderColor: formik.errors
                                                .firstName
                                                ? "red"
                                                : "",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group half-fld">
                                        <div class="frm-flds">
                                          <label
                                            for="last_name_bluesnap"
                                            class="input-label"
                                          >
                                            Last name *
                                          </label>
                                          <Field
                                            type="text"
                                            className="input-flds-2 required w-input"
                                            placeholder="Last Name*"
                                            id="lastName"
                                            name="lastName"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            style={{
                                              borderColor: formik.errors
                                                .lastName
                                                ? "red"
                                                : "",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="frm-flds">
                                        <label
                                          for="address1_bluesnap"
                                          class="input-label"
                                        >
                                          Address *
                                        </label>
                                        <LoadScript
                                          googleMapsApiKey="AIzaSyCQpu96zl_XDvk6O2z98bwhTmDzmNjHwoA"
                                          libraries={libraries}
                                        >
                                          <Autocomplete
                                            onLoad={onLoad}
                                            onPlaceChanged={() =>
                                              onPlaceChanged(formik)
                                            }
                                            options={{
                                              componentRestrictions: {
                                                country: "us",
                                              },
                                            }}
                                            className="w-100p"
                                          >
                                            <Field
                                              type="text"
                                              className="input-flds-2 required w-input"
                                              placeholder="Address*"
                                              id="line1"
                                              name="line1"
                                              value={formik.values.line1}
                                              style={{
                                                borderColor: formik.errors.line1
                                                  ? "red"
                                                  : "",
                                              }}
                                            />
                                          </Autocomplete>
                                        </LoadScript>
                                      </div>
                                    </div>
                                    <div class="rtc-grid mobile">
                                      <div class="form-group half-fld fw">
                                        <div class="frm-flds">
                                          <label
                                            for="shipping_address2_bluesnap"
                                            class="input-label ct"
                                          >
                                            Apartment, suite, etc. (optional)
                                          </label>
                                          <Field
                                            type="text"
                                            className="input-flds-2 w-input"
                                            placeholder="Appartment, suite, etc. (optional)"
                                            id="line2"
                                            name="line2"
                                            value={formik.values.line2}
                                            onChange={formik.handleChange}
                                            style={{
                                              borderColor: formik.errors.line2
                                                ? "red"
                                                : "",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="rtc-grid mobile">
                                      <div class="form-group half-fld _32-percent">
                                        <div class="frm-flds">
                                          <div class="select-form-wrapper">
                                            <label
                                              for="shipping_state_bluesnap"
                                              class="input-label float"
                                            >
                                              State *
                                            </label>
                                            <select
                                              className="input-flds-2 select-field required w-select"
                                              id="state"
                                              name="state"
                                              value={formik.values.state}
                                              onChange={formik.handleChange}
                                              style={{
                                                borderColor: formik.errors.state
                                                  ? "red"
                                                  : "",
                                              }}
                                            >
                                              <option value="">
                                                -Select State-
                                              </option>
                                              {meta.states.map(
                                                (state, index) => (
                                                  <option
                                                    value={state.key}
                                                    key={index}
                                                  >
                                                    {state.name}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <div class="caret-down">
                                              <img
                                                alt=""
                                                loading="lazy"
                                                src="https://uploads-ssl.webflow.com/6354b2a076a5d651155d856e/6354b2a076a5d62c5a5d8586_select-checkout.svg"
                                                class="image-123"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group _32-percent">
                                        <div class="frm-flds">
                                          <label
                                            for="city_bluesnap"
                                            class="input-label"
                                          >
                                            City *
                                          </label>
                                          <Field
                                            type="text"
                                            className="input-flds-2 required w-input"
                                            placeholder="City*"
                                            id="city"
                                            name="city"
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            style={{
                                              borderColor: formik.errors.city
                                                ? "red"
                                                : "",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group _32-percent">
                                        <div class="frm-flds">
                                          <label
                                            for="zip_bluesnap"
                                            class="input-label ct"
                                          >
                                            ZIP code *
                                          </label>
                                          <Field
                                            type="text"
                                            className="input-flds-2 required w-input"
                                            placeholder="Zip Code*"
                                            id="postal_code"
                                            name="postal_code"
                                            value={formik.values.postal_code}
                                            onChange={formik.handleChange}
                                            style={{
                                              borderColor: formik.errors
                                                .postal_code
                                                ? "red"
                                                : "",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="frm-flds">
                                        <label
                                          for="phone_bluesnap"
                                          class="input-label phone-float"
                                        >
                                          Phone
                                        </label>
                                        <Field
                                          type="tel"
                                          className="input-flds-2 required w-input"
                                          placeholder="Phone*"
                                          id="phone"
                                          name="phone"
                                          value={formik.values.phone}
                                          onChange={formik.handleChange}
                                          style={{
                                            borderColor: formik.errors.phone
                                              ? "red"
                                              : "",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>

                              <div
                                class="payment-flds-box last-child jh"
                                ref={billingFormRef}
                              >
                                <div class="pd-div">
                                  <h4 class="ss-info-heading">
                                    Payment Details
                                  </h4>
                                  <div class="re3-txt">
                                    All transactions are secure and encrypted.
                                  </div>
                                </div>
                                <div class="cc-info-box boxcc credit_card_radio radio_btn_checked">
                                  <div>
                                    <label
                                      checked=""
                                      class="cc-sel-box w-radio"
                                    >
                                      <div class="w-form-formradioinput w-form-formradioinput--inputType-custom pd-radio w-radio-input"></div>

                                      <input
                                        id="billing_use_shipping_true_input-1"
                                        type="radio"
                                        name=""
                                        data-name=""
                                        style={{
                                          opacity: "0",
                                          position: "absolute",
                                          zIndex: "-1",
                                        }}
                                        value="true"
                                      />
                                      <span
                                        for="billing_use_shipping_true_input-1"
                                        class="cc-sel-txt w-form-label"
                                      >
                                        Credit card
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    <img
                                      alt=""
                                      loading="lazy"
                                      src="../images/cc-light_2.svg"
                                      class="cc-info-badge-img"
                                    />
                                  </div>
                                </div>
                                <div class="payment-selector">
                                  <div class="cc-header">
                                    <div class="cc-form-2 ds1 payment_details">
                                      <Field
                                        type="text"
                                        className="required input-flds w-input"
                                        placeholder="Card Number*"
                                        id="cc-number"
                                        name="cardNumber"
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                        maxLength={19}
                                        style={{
                                          borderColor: errors.cardNumber
                                            ? "red"
                                            : "",
                                        }}
                                      />
                                      <div class="form-group">
                                        <div class="frm-flds-3 mb_10"></div>
                                        <div
                                          id="exp_month_year"
                                          class="frm-flds-3 half-fld fl"
                                        >
                                          <Field
                                            id="cc-exp"
                                            name="expdate"
                                            render={({ field }) => (
                                              <div>
                                                <InputMask
                                                  className="required masked input-flds w-input"
                                                  {...field}
                                                  mask="99/99"
                                                  placeholder="MM/YY"
                                                  maskChar={null}
                                                  value={expirationDate}
                                                  onChange={
                                                    handleExpirationDateChange
                                                  }
                                                  style={{
                                                    borderColor:
                                                      errors.expirationDate
                                                        ? "red"
                                                        : "",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          />
                                        </div>
                                        <div class="frm-flds-3 half-fld fl">
                                          <Field
                                            id="cc-csc"
                                            name="cvc"
                                            render={({ field }) => (
                                              <div>
                                                <InputMask
                                                  className="required masked input-flds w-input"
                                                  {...field}
                                                  mask="9999"
                                                  placeholder="CVV"
                                                  maskChar={null}
                                                  value={cvv}
                                                  onChange={handleCvvChange}
                                                  style={{
                                                    borderColor: errors.cvv
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <div
                                          id="exp_month_year"
                                          class="frm-flds-3 half-fld fl gty22"
                                        >
                                          <input
                                            class="required masked input-flds w-input"
                                            maxlength="256"
                                            name="card_name"
                                            data-name="card_name"
                                            pattern="(1[0-2]|0[1-9])\/\d\d"
                                            placeholder="Name on card"
                                            data-valid-example="07/28"
                                            type="text"
                                            id="card_name"
                                          />
                                        </div>
                                      </div>
                                      <div class="protection-block-3 hh1">
                                        <div class="div-block-631">
                                          <div class="addon-checkmark ds1 sw">
                                            <img
                                              src="../images/right_1.svg"
                                              loading="lazy"
                                              alt=""
                                              class="image-135"
                                            />
                                          </div>
                                          <p
                                            data-i18n="protection_message"
                                            class="checkout-form-secure-2 gfr t5"
                                          >
                                            Use shipping address as billing
                                            address
                                            <br />
                                          </p>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="billing-details ">
                                          <input
                                            id="isBillingAddress"
                                            type="checkbox"
                                            checked={isBillingAddress}
                                            onChange={(e) => {
                                              setIsBillingAddress(
                                                e.target.checked
                                              );
                                              resetBillingAddress(
                                                formik,
                                                e.target.checked
                                              );
                                            }}
                                          />
                                          <span className="mt-5">
                                            <label for="isBillingAddress">
                                              Use shipping address as billing
                                              address
                                            </label>
                                          </span>
                                        </div>

                                        <div
                                          style={{
                                            display: !isBillingAddress
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          <div class="rtc-grid">
                                            <div class="form-group half-fld">
                                              <div class="frm-flds">
                                                <label
                                                  for="first_name_bluesnap"
                                                  class="input-label"
                                                >
                                                  First name *
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="input-flds-2 required w-input"
                                                  placeholder="First Name*"
                                                  id="billingAddress.firstName"
                                                  name="billingAddress.firstName"
                                                  value={
                                                    formik.values.billingAddress
                                                      .firstName
                                                  }
                                                  onChange={formik.handleChange}
                                                  style={{
                                                    borderColor: formik.errors
                                                      .billingAddress?.firstName
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group half-fld">
                                              <div class="frm-flds">
                                                <label
                                                  for="last_name_bluesnap"
                                                  class="input-label"
                                                >
                                                  Last name *
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="input-flds-2 required w-input"
                                                  placeholder="Last Name*"
                                                  id="billingAddress.lastName"
                                                  name="billingAddress.lastName"
                                                  value={
                                                    formik.values.billingAddress
                                                      .lastName
                                                  }
                                                  onChange={formik.handleChange}
                                                  style={{
                                                    borderColor: formik.errors
                                                      .billingAddress?.lastName
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="frm-flds">
                                              <label
                                                for="address1_bluesnap"
                                                class="input-label"
                                              >
                                                Address *
                                              </label>
                                              <LoadScript
                                                googleMapsApiKey="AIzaSyCQpu96zl_XDvk6O2z98bwhTmDzmNjHwoA"
                                                libraries={libraries}
                                              >
                                                <Autocomplete
                                                  onLoad={onLoadBilling}
                                                  onPlaceChanged={() =>
                                                    onPlaceChangedBilling(
                                                      formik
                                                    )
                                                  }
                                                  options={{
                                                    componentRestrictions: {
                                                      country: "us",
                                                    },
                                                  }}
                                                  className="w-100p"
                                                >
                                                  <Field
                                                    type="text"
                                                    className="input-flds-2 required w-input"
                                                    placeholder="Address*"
                                                    id="billingAddress.line1"
                                                    name="billingAddress.line1"
                                                    value={
                                                      formik.values
                                                        .billingAddress.line1
                                                    }
                                                    style={{
                                                      borderColor: formik.errors
                                                        .billingAddress?.line1
                                                        ? "red"
                                                        : "",
                                                    }}
                                                  />
                                                </Autocomplete>
                                              </LoadScript>
                                            </div>
                                          </div>
                                          <div class="rtc-grid mobile">
                                            <div class="form-group half-fld fw">
                                              <div class="frm-flds">
                                                <label
                                                  for="shipping_address2_bluesnap"
                                                  class="input-label ct"
                                                >
                                                  Apartment, suite, etc.
                                                  (optional)
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="input-flds-2 w-input"
                                                  placeholder="Appartment, suite, etc. (optional)"
                                                  id="billingAddress.line2"
                                                  name="billingAddress.line2"
                                                  value={
                                                    formik.values.billingAddress
                                                      .line2
                                                  }
                                                  onChange={formik.handleChange}
                                                  style={{
                                                    borderColor: formik.errors
                                                      .billingAddress?.line2
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="rtc-grid mobile">
                                            <div class="form-group half-fld _32-percent">
                                              <div class="frm-flds">
                                                <div class="select-form-wrapper">
                                                  <label
                                                    for="shipping_state_bluesnap"
                                                    class="input-label float"
                                                  >
                                                    State *
                                                  </label>
                                                  <select
                                                    className="input-flds-2 select-field required w-select"
                                                    id="billingAddress.state"
                                                    name="billingAddress.state"
                                                    value={
                                                      formik.values
                                                        .billingAddress?.state
                                                    }
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    style={{
                                                      borderColor: formik.errors
                                                        .billingAddress?.state
                                                        ? "red"
                                                        : "",
                                                    }}
                                                  >
                                                    <option value="">
                                                      -Select State-
                                                    </option>
                                                    {meta.states.map(
                                                      (state, index) => (
                                                        <option
                                                          value={state.key}
                                                          key={index}
                                                        >
                                                          {state.name}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  <div class="caret-down">
                                                    <img
                                                      alt=""
                                                      loading="lazy"
                                                      src="https://uploads-ssl.webflow.com/6354b2a076a5d651155d856e/6354b2a076a5d62c5a5d8586_select-checkout.svg"
                                                      class="image-123"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group _32-percent">
                                              <div class="frm-flds">
                                                <label
                                                  for="city_bluesnap"
                                                  class="input-label"
                                                >
                                                  City *
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="input-flds-2 required w-input"
                                                  placeholder="City*"
                                                  id="billingAddress.city"
                                                  name="billingAddress.city"
                                                  value={
                                                    formik.values.billingAddress
                                                      .city
                                                  }
                                                  onChange={formik.handleChange}
                                                  style={{
                                                    borderColor: formik.errors
                                                      .billingAddress?.city
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group _32-percent">
                                              <div class="frm-flds">
                                                <label
                                                  for="zip_bluesnap"
                                                  class="input-label ct"
                                                >
                                                  ZIP code *
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="input-flds-2 required w-input"
                                                  placeholder="Zip Code*"
                                                  id="billingAddress.postal_code"
                                                  name="billingAddress.postal_code"
                                                  value={
                                                    formik.values.billingAddress
                                                      .postal_code
                                                  }
                                                  onChange={formik.handleChange}
                                                  style={{
                                                    borderColor: formik.errors
                                                      .billingAddress
                                                      ?.postal_code
                                                      ? "red"
                                                      : "",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="payment-agree-3">
                              By clicking the button below, you agree with our{" "}
                              <a
                                href="http://offer.getnuzzle.com/legal/terms-conditions"
                                target="_blank"
                                class="agree-link"
                              >
                                Terms of Use
                              </a>{" "}
                              and{" "}
                              <a
                                href="http://offer.getnuzzle.com/legal/privacy-policy"
                                target="_blank"
                                class="agree-link"
                              >
                                Privacy Policy
                              </a>
                              .
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="cc-trust v02cc">
                    <img
                      src="../images/cc-light.svg"
                      loading="lazy"
                      alt=""
                      class="credit-card"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="delivery-div get1">
              <div class="div-block-639">
                <img
                  src="../images/can-post-img.svg"
                  loading="lazy"
                  width="27"
                  alt=""
                />
              </div>
              <div>
                <div class="delivery-text">
                  <span class="text-span-132">Ships By:</span>{" "}
                  <span class="date-3">{deliverDate}</span> via USPS
                </div>
              </div>
            </div>
            <div class="cc-button-box newbtnchange proceed-secure-checkout">
              <button
                className={`main-cc-btn-new first_submit_btn w-inline-block ${
                  loading ? "order-processing" : ""
                }`}
                style={{
                  backgroundColor: loading ? "#294a59" : "#29af5c",
                }}
                disabled={loading}
                type="submit"
                onClick={() => handleScroll(formik)}
              >
                <div>
                  {loading ? (
                    <span className="pay-now">
                      <i> Processing Order</i>{" "}
                      <PulseLoader color="#ffffff" size={8} />
                    </span>
                  ) : (
                    <span>PAY NOW</span>
                  )}
                </div>
                {!loading && (
                  <div>
                    <img
                      width="153.5"
                      alt=""
                      src="../images/a34.svg"
                      loading="lazy"
                      class="image-109 gfr1"
                    />
                  </div>
                )}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default NMI;
