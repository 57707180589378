import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import Reviews from "./review";
import Details from "./details/main";
import { trackAddToCartEvent } from "mixpanel/events";
import Trust from "./trust";

function Checkout() {
  useEffect(() => {
    trackAddToCartEvent();
  }, []);

  return (
    <div>
      <Header />
      <Details />
      <Trust />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Checkout;
