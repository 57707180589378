import React, { useEffect } from "react";
import Header from "./header";
import Banner from "./banner";
import SliderMain from "./slider";
import Info from "./info";
import RatingReviews from "./rating-reviews";
import FaqMoney from "./faq-money";
import Extras from "./extras";
import Footer from "./footer";
import { trackPageViewEvent } from "mixpanel/events";
import mainStore from "store/store";

function Home() {
  const {
    allPages,
    setAllPages,
    paymentIntent,
    setPaymentIntent,
    orderPlaced,
    setOrderPlaced,
    setTaboolaClickId,
    setVoluumCid,
    setUtmParams,
  } = mainStore();
  useEffect(() => {
    resetStoreData();
    setTablooladId();
    trackPageViewEvent({ page_type: "Home" });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  useEffect(() => {
    console.log("allPages", allPages);
    console.log("paymentIntent", paymentIntent);
    console.log("orderPlaced", orderPlaced);
  }, [allPages, paymentIntent, orderPlaced]);

  const resetStoreData = () => {
    setAllPages([]);
    setPaymentIntent(null);
    setOrderPlaced(false);
  };

  const setTablooladId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const tclid = queryParams.get("click_id");
    console.log("setTablooladId ~ tclid:", tclid);

    const params = new URLSearchParams(window.location.search);
    const utm_source = params.get("utm_source");
    const utm_campaign = params.get("utm_campaign");
    const utm_medium = params.get("utm_medium");

    setUtmParams({
      utm_source: utm_source,
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
    });

    if (tclid) {
      setTaboolaClickId(tclid);
    }

    if (
      window.dtpCallback &&
      typeof window.dtpCallback.getClickID === "function"
    ) {
      const clickID = window.dtpCallback.getClickID();
      console.log("dpt Click ID:", clickID);
      setVoluumCid(clickID);
    } else {
      console.warn(
        "window.dtpCallback.getClickID is not defined or not a function"
      );
    }
  };

  return (
    <div class="wrapper mobile-lp3">
      <Header />
      <SliderMain />
      <Info />
      <RatingReviews />
      <Extras />
      <Footer />
    </div>
  );
}

export default Home;
