import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";
import mainStore from "store/store";
import { trackUpsellAccepted, trackPageViewEvent } from "mixpanel/events";
import { useLocation } from "react-router-dom";
import PurchaseStore from "store/purchase";

function UpSell3B() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    allProductOffers,
    paymentIntent,
    formattedTime,
    allPages,
    setAllPages,
    orderPlaced,
    setPaymentIntent,
  } = mainStore();
  const { managePurchase } = PurchaseStore();

  const [loading, setLoading] = useState(false);
  const [loadingGLobal, setLoadingGlobal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});

  useEffect(() => {
    initializeUpsell3B();
  }, []);

  useEffect(() => {
    if (window.hj) {
      window.hj("stateChange", location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (paymentIntent) {
      const pillowcases = allProductOffers.products.find((cases) => {
        return cases.sku === "NZ4002";
      });
      console.log("pillowcases ~ pillowcases:", pillowcases);

      if (pillowcases) {
        setSelectedDeal(pillowcases);
      }
    }
  }, [allProductOffers, paymentIntent]);

  const initializeUpsell3B = async () => {
    if (!paymentIntent) {
      navigate("/");
      return;
    }

    if (allPages.includes("up-sell-3b") || orderPlaced) {
      navigate("/thank-you");
      return;
    }

    const pages = [...allPages];
    pages.push("up-sell-3b");
    setAllPages(pages);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    trackPageViewEvent({ page_type: "Upsell 3B" });
  };

  const onAddPillow = async () => {
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    newPaymentIntent.finalizePurchase = true;
    newPaymentIntent.details.items.push({
      title: selectedDeal.title,
      price: selectedDeal.afterDiscountValue,
      quantity: 1,
      sku: selectedDeal.sku,
      product_id: selectedDeal.productId,
    });

    setLoading(true);
    const res = await managePurchase(newPaymentIntent);

    if (res?.success) {
      trackUpsellAccepted(selectedDeal.sku);
      setPaymentIntent(res.data);
      navigate("/thank-you");
      setLoading(false);
    } else {
      alert(res?.errorMessage);
      setLoading(false);
    }
  };

  const navigateToNextUpsell = async () => {
    if (loading) {
      return;
    }
    setLoadingGlobal(true);
    const newPaymentIntent = { ...paymentIntent };
    newPaymentIntent.step = "step-upsell";
    newPaymentIntent.finalizePurchase = true;
    const res = await managePurchase(newPaymentIntent);
    if (res?.success) {
      setPaymentIntent(res.data);
      navigate("/thank-you");
    } else {
      alert(res?.errorMessage);
    }
    setLoadingGlobal(false);
    setLoading(false);
  };

  return (
    <>
      {loadingGLobal && (
        <div className="global-loader">
          <ClipLoader color="#fff" size={100} />
        </div>
      )}
      <div className="progressbar-section">
        <div>
          <img
            src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f222c4dc2ef4647b6c690_progress%20bar.svg"
            loading="lazy"
            alt=""
            className="image-18"
          />
        </div>
      </div>
      <div className="logo-section">
        <div className="container-3 container-upsell w-container">
          <div className="upsell-header">
            <div className="logo-block upsell-logo-block">
              <img
                src="../images/logo_v02.png"
                loading="lazy"
                width="113"
                alt=""
              />
            </div>
            <div className="progress-container">
              <div className="progress-step">
                <a href="#" className="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2d756903139a8964573_one.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f27534ea18b1c3eedbe8a_st1.svg"
                    loading="lazy"
                    alt=""
                    className="pr-r r-pr-r"
                  />
                </a>
                <p className="progress-p">Checkout</p>
              </div>
              <div className="progress-step">
                <a href="#" className="progress-link w-inline-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2ebbce1fa36907e580e_two.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f277596949e5bacc01176_st2.svg"
                    loading="lazy"
                    width="414"
                    alt=""
                    className="pr-r c-pr-r"
                  />
                </a>
                <p className="progress-p">Special Deal</p>
              </div>
              <div className="progress-step ls">
                <div className="progress-link">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa2f9037c341fc9b203c4_three.png"
                    loading="lazy"
                    alt=""
                    className="image-21 link-img"
                  />
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f279000ea000b17acdf08_st3.svg"
                    loading="lazy"
                    alt=""
                    className="pr-r l-pr-r"
                  />
                </div>
                <p className="progress-p">Summary</p>
              </div>
              <img
                src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625fa9a0a2c9d72c22d4eb3c_rl.svg"
                loading="lazy"
                alt=""
                className="image-22"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main-section main-section-upsell">
        <div className="container-3 container-upsell main-container-upsell w-container">
          <div className="upsell-main-top-block">
            <h5 className="sale-title">
              HOLD ON! HERE’S EVEN{" "}
              <span className="text-span-91">BETTER DEAL!</span>
            </h5>
          </div>
          <div className="timer-div">
            <div className="note-text">
              This exclusive, limited time offer expires in{" "}
              <span id="timer" className="checkout-timer">
                {formattedTime}
              </span>{" "}
              minutes, so don&#x27;t miss out.
            </div>
          </div>
          <div className="center-div">
            <p className="sale-p">
              Our customers come back time and time again asking for this same
              deal when they want to order more Nuzzle pillows. And it&#x27;s no
              surprise, with savings this good, it&#x27;s hard to pass up.
            </p>
          </div>
          <div className="columns upsell-cl w-row">
            <div className="column-2 upsell-slider-coloumn w-col w-col-6">
              <div className="upsell-slider-wrapper">
                <div className="main-dots upsell-dots">
                  <div className="slide-dot">
                    <img
                      src="../images/Upsell-Funnel--06.jpg"
                      loading="lazy"
                      sizes="(max-width: 479px) 96vw, (max-width: 767px) 100vw, (max-width: 991px) 45vw, 445px"
                      srcset="
                      ../images/Upsell-Funnel--06-p-500.jpg   500w,
                      ../images/Upsell-Funnel--06-p-800.jpg   800w,
                      ../images/Upsell-Funnel--06-p-1080.jpg 1080w,
                      ../images/Upsell-Funnel--06-p-1600.jpg 1600w,
                      ../images/Upsell-Funnel--06-p-2000.jpg 2000w,
                      ../images/Upsell-Funnel--06.jpg        2083w
                    "
                      alt=""
                      className="slide-dot-iimg"
                    />
                  </div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                  <div className="slide-dot"></div>
                </div>
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f176839d9035414ca219f_prev.svg"
                  loading="lazy"
                  alt=""
                  className="prev-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625f17805bff702023229745_next.svg"
                  loading="lazy"
                  alt=""
                  className="next-arr slick-arrow upsell-arr"
                />
                <img
                  src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/6270e07371b4bf03848279bc_best.png"
                  loading="lazy"
                  alt=""
                  className="best-slider-icon"
                />
              </div>
            </div>
            <div className="column-4 w-col w-col-6">
              <div
                id="redeem"
                className="main-text-block upsell-main-text-block"
              >
                <h1 className="main-title _2ndname">Nuzzle Pillow</h1>
                <div className="stars-block">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/623750a74497753d5f3105f3_rating%20stars.svg"
                    loading="lazy"
                    alt=""
                  />
                  <p className="stars-text upsell-stars-text">410 Reviews</p>
                </div>
                <div className="gift-row upsell-gift-row">
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625e96079fa1145743416b70_free.svg"
                    loading="lazy"
                    alt=""
                    className="image-13"
                  />
                  <div className="gift-text-block">
                    <p className="paragraph-15 upsell-p15">
                      <strong className="bold-text-3">
                        This offer is available only with today’s purchase!
                      </strong>
                    </p>
                  </div>
                </div>
                <p className="paragraph-11 upsell-p11">
                  We want to give you one last opportunity to add an extra
                  Nuzzle pillow to your order. But here&#x27;s the thing, once
                  you leave this page, this deal is gone for good. So don&#x27;t
                  let it slip away.
                  <br />
                </p>
                <div className="re-div">
                  <div>
                    <div className="text-block-117">
                      Regular Price: ${selectedDeal.retailValue}
                    </div>
                  </div>
                  <div className="div-block-456">
                    <div className="text-block-118">
                      Instant Savings: $
                      {(
                        selectedDeal.retailValue -
                        selectedDeal.afterDiscountValue
                      ).toFixed(2)}
                    </div>
                  </div>
                </div>
                <div className="pp_div">
                  <div className="div_ss">
                    <div className="div-block-459">
                      <div className="div-block-460">
                        <div className="text-block-120">
                          Your Price: ${selectedDeal.afterDiscountValue}
                        </div>
                      </div>
                      <div>
                        <h1 className="heading-24"></h1>
                      </div>
                      <div>
                        <div className="text-block-122">+ FREE S+H</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-upsell-type="add-variant"
                  className="accept_upsell_button checkout-accept-upsell w-inline-block"
                >
                  <button
                    className={`button-gold checkout-accept-upgrade-downsell1 ${
                      loading ? "order-processing" : ""
                    }`}
                    style={{
                      backgroundColor: loading ? "#294a59" : "#29af5c",
                    }}
                    disabled={loading}
                    onClick={() => onAddPillow()}
                  >
                    <div className="button-txt-icon-2">
                      <div className="add-to-order-2">
                        {loading ? (
                          <span className="pay-now">
                            <i> Processing Order</i>{" "}
                            <PulseLoader color="#ffffff" size={8} />
                          </span>
                        ) : (
                          <strong>YES! ADD TO MY ORDER!</strong>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
                <div className="form-information upsell-form-inf">
                  <div className="g-text">
                    TRY IT RISK FREE! 90-DAY MONEY BACK GUARANTEE
                  </div>
                </div>
                <a
                  className="no-offer-block w-inline-block cursor-pointer"
                  onClick={() => navigateToNextUpsell()}
                >
                  <p className="no-offer-text">
                    No, thank you! I don&#x27;t want this one time offer.
                  </p>
                  <img
                    src="https://uploads-ssl.webflow.com/623750a7449775b9fa3105d6/625ef1f759479c8b18be44f8_Path.svg"
                    loading="lazy"
                    alt=""
                    className="image-17"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-6">
        <div className="inner-upsell">
          <div className="text-block-24">
            <a
              href="../legal/privacy-policy.html"
              target="_blank"
              className="link-5"
            >
              Privacy Policy
            </a>
            | 
            <a
              href="../legal/terms-conditions.html"
              target="_blank"
              className="link-5"
            >
              Terms &amp; Conditions
            </a>
            |
            <a
              href="../legal/return-policy.html"
              target="_blank"
              className="link-8"
            >
              Refund Policy
            </a>
            |
            <a
              href="../legal/contact-us.html"
              target="_blank"
              className="link-9"
            >
              Contact
            </a>
            | 
            <a href="../about-us.html" target="_blank" className="link-10">
              About
            </a>
            <br />© 2023 Nuzzle. All Rights Reserved.
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default UpSell3B;
