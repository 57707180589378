function Extras() {
  return (
    <div>
      <div id="guarantee-link" class="gua-sec w-container">
        <div class="gua-block">
          <div class="gua-div">
            <div class="div-block-506">
              <img
                src="images/gg1.png"
                loading="lazy"
                width="170"
                alt="nuzzle, canada, pillow, sleep"
                class="gua-img"
              />
            </div>
            <div class="dua-text-block">
              <h1 class="con-heading-left">
                Try It At Our Risk For <br />
                90 Days Or Your Money Back
              </h1>
              <div class="three-con-para guatext">
                Try it for 90 days entirely at our risk. If you are not
                absolutely delighted with the Nuzzle pillow from the very first
                night, simply return it for a full refund. Contact our full-time
                dedicated support team for assistance, no questions asked.
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="section-11">
        <div class="container-22">
          <div class="footer-wrapper">
            <img
              src="images/logo.png"
              loading="lazy"
              width="129"
              alt="Footer-Logo"
              class="footer-logo"
            />
            <div class="footer-copy-sc">
              <div class="links-footer-1-2">
                <a href="#" target="_blank" class="link-footer-2">
                  Privacy Policy
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Terms &amp; Conditions
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Refund Policy
                </a>
              </div>
              <p class="footer-copy-1-2">
                ©️ 2022 Nuzzle . All Rights Reserved.
              </p>
            </div>
            <p class="footer-text-info-2">
              Distributed by Ontel Products, 21 Law Drive, Fairfield, NJ 07004
            </p>
            <div class="cc-list-2">
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd747138da10a_credit-card-4.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd744d48da0da_credit-card-5.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd711f38da103_credit-card-3.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd75e358da0df_credit-card-2.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd78db28da0d9_credit-card-1%20(1).svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
            </div>
          </div>
        </div>
      </section>
      <div class="mid-sec-ban">
        <div class="container-20 w-container">
          <div class="div-block-505">
            <div class="div-block-555">
              <h1 class="heading-50">
                I fell asleep so fast and stayed asleep all night.
              </h1>
              <h1 class="heading-51">
                How You Can Get the Support You Need for a Restful Sleep
              </h1>
            </div>
            <div class="div-block-521">
              <a
                data-w-id="d5bd3614-db1c-56c5-28b8-38a799fe1fee"
                style={{ opacity: "0" }}
                href="#"
                class="button-7 w-button"
              >
                GET 50% OFF TODAY! <span class="text-span-110"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="offer-sec">
        <div class="offer-con w-container">
          <div class="offer-div">
            <div class="of-left-div">
              <h1 class="heading-53">Special Offer on Now</h1>
              <h1 class="heading-54">Order Today and Get 50% OFF</h1>
              <p class="paragraph-29">
                This discount is valid for a limited time only.
                <br />
                Stocks are limited – act now!
              </p>
              <a href="#" class="button-10 w-button">
                CLAIM YOUR DISCOUNT NOW
              </a>
            </div>
            <div class="of-right-div">
              <img
                src="images/pillow.webp"
                loading="lazy"
                alt=""
                width="414"
                class="image-113 f-d"
              />
              <img
                src="images/pillow.webp"
                loading="lazy"
                alt=""
                width="309"
                class="image-113 f-m"
              />
            </div>
          </div>
        </div>
      </div>
      <section class="money-back-sec">
        <div class="container-17">
          <div class="section-wrapper-2">
            <div class="guarantee-sc-d">
              <img
                src="images/90days.png"
                loading="eager"
                id="w-node-d5bd3614-db1c-56c5-28b8-38a799fe2007-a91c7a14"
                alt="Guarantee-img"
                width="80"
                class="guarantee-img-d fordesk1"
              />
              <img
                src="images/60-days_v02.png"
                loading="eager"
                width="145"
                id="w-node-d5bd3614-db1c-56c5-28b8-38a799fe2008-a91c7a14"
                alt="Guarantee-img"
                class="guarantee-img-d formobile1"
              />
              <div>
                <div class="guarantee-title-2">
                  Try the Nuzzle Pillow – It’s Totally Risk-Free!
                </div>
                <div class="text-block-150">
                  With the incredible sleep and potential health benefits you
                  get with the Nuzzle Pillow, it’s a bargain at any price! But
                  if you use the limited time discount when making your order,
                  you’ll only pay $79.99 instead of the regular price of $138.
                  That’s a huge savings opportunity you can’t afford to miss out
                  on!
                  <br />
                  <br />
                  And just in case you aren&#x27;t fully satisfied with the
                  Nuzzle Pillow, we’re offering you our 90-day money-back
                  guarantee. Send your package back and get a full refund, no
                  questions asked!
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section-2-2">
        <div class="container-22">
          <div class="footer-wrapper">
            <img
              src="images/logo.png"
              loading="lazy"
              width="129"
              alt="Footer-Logo"
              class="footer-logo"
            />
            <div class="footer-copy-sc">
              <div class="links-footer-1-2">
                <a href="#" target="_blank" class="link-footer-2">
                  Privacy Policy
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Terms &amp; Conditions
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Refund Policy
                </a>
              </div>
              <p class="footer-copy-1-2">
                ©️ 2022 Nuzzle . All Rights Reserved.
              </p>
            </div>
            <p class="footer-text-info-2">
              Distributed by Ontel Products, 21 Law Drive, Fairfield, NJ 07004
            </p>
            <div class="cc-list-2">
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd747138da10a_credit-card-4.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd744d48da0da_credit-card-5.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd711f38da103_credit-card-3.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd75e358da0df_credit-card-2.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd78db28da0d9_credit-card-1%20(1).svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section-10 try-it-sc">
        <div class="container-22">
          <div class="section-wrapper-2">
            <div class="try-it-heading">
              <h1 class="heading-52">Try it Risk Free</h1>
            </div>
            <div class="try-sc">
              <div class="try-sc-item">
                <div class="try-icon-wrapper">
                  <img
                    src="images/ti.svg"
                    loading="lazy"
                    alt="try-sc-img"
                    class="try-sc-img"
                  />
                </div>
                <h4 class="try-it-title">Free Shipping</h4>
                <div class="try-sc-texts">
                  <p class="try-it-text">
                    Free Shipping on all orders to the lower 48 states
                    <strong>
                      <br />
                    </strong>
                  </p>
                </div>
              </div>
              <div class="try-sc-item">
                <div class="try-icon-wrapper">
                  <img
                    src="images/fa.svg"
                    loading="lazy"
                    alt="try-sc-img"
                    class="try-sc-img"
                  />
                </div>
                <h4 class="try-it-title">Fast Shipping</h4>
                <div class="try-sc-texts-2">
                  <p class="try-it-text">
                    Fast, reliable, and tracked shipping
                    <strong>
                      <br />
                    </strong>
                  </p>
                </div>
              </div>
              <div class="try-sc-item">
                <div class="try-icon-wrapper gtr">
                  <img
                    src="images/mo.svg"
                    loading="lazy"
                    alt="try-sc-img"
                    class="try-sc-img hyt"
                  />
                </div>
                <h4 class="try-it-title">24/7 Service</h4>
                <div class="try-sc-texts-3">
                  <p class="try-it-text">
                    Fast and Friendly customer support via email or live chat
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="faq-link" class="faq-sec">
        <div class="container-22">
          <div class="section-wrapper-2 faqmob">
            <div class="heading-block-5">
              <h1 class="text-center-2">Frequently Asked Questions</h1>
            </div>
            <div class="faq-sc">
              <div class="faq-list">
                <div
                  data-w-id="d5bd3614-db1c-56c5-28b8-38a799fe2056"
                  class="faq-item faq-itemborder"
                >
                  <div class="faq-item-head">
                    <div class="question-text-2">
                      <strong>
                        Can the Nuzzle pillow be washed and dried?
                      </strong>
                    </div>
                    <div class="faq-icon-2"></div>
                  </div>
                  <div
                    style={{ width: "100%", height: "0px" }}
                    class="faq-content"
                  >
                    <div class="faq-content-wrapper">
                      <p class="faq-text-3">
                        Yes, the Nuzzle pillow is machine washable and dryable.
                        It is recommended to wash it on a gentle cycle and
                        tumble dry on low heat.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="d5bd3614-db1c-56c5-28b8-38a799fe2062"
                  class="faq-item faq-itemborder"
                >
                  <div class="faq-item-head">
                    <div class="question-text-2 hide">
                      <strong>
                        Can Nuzzle be used by people of all sleep positions?
                      </strong>
                    </div>
                    <div class="question-text-mb-2">
                      How do I adjust or cancel my subscription? <br />I need to
                      change address or delivery time
                    </div>
                    <div class="faq-icon-2"></div>
                  </div>
                  <div
                    style={{ width: "100%", height: "0px" }}
                    class="faq-content"
                  >
                    <div class="faq-content-wrapper">
                      <p class="faq-text-3">
                        Yes, Nuzzle is designed to adapt to all sleep positions,
                        providing the perfect support for your head and neck,
                        regardless of whether you are a side, back, stomach or
                        combination sleeper.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="d5bd3614-db1c-56c5-28b8-38a799fe2072"
                  class="faq-item faq-itemborder"
                >
                  <div class="faq-item-head">
                    <div class="question-text-2 hide">
                      <strong>
                        Will the Nuzzle pillow lose its shape over time?
                      </strong>
                    </div>
                    <div class="question-text-mb-2">
                      What are the production methods? <br />
                      Do they really use less energy and water?
                    </div>
                    <div class="faq-icon-2"></div>
                  </div>
                  <div
                    style={{ width: "100%", height: "0px" }}
                    class="faq-content"
                  >
                    <div class="faq-content-wrapper">
                      <p class="faq-text-3">
                        No, Nuzzle has been lab tested to retain its shape for
                        over 1001 days and nights, even after repeated washing.
                        The Nanocoils provide controlled support to each
                        sleeper, ensuring the pillow maintains its fluff and
                        flex over a long period of time.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="d5bd3614-db1c-56c5-28b8-38a799fe2082"
                  class="faq-item faq-itemborder"
                >
                  <div class="faq-item-head">
                    <div class="question-text-2">
                      <strong>
                        Can the Nuzzle pillow be adjusted to different firmness
                        levels?
                      </strong>
                    </div>
                    <div class="faq-icon-2"></div>
                  </div>
                  <div
                    style={{ width: "100%", height: "0px" }}
                    class="faq-content"
                  >
                    <div class="faq-content-wrapper">
                      <p class="faq-text-3">
                        Yes, Nuzzle&#x27;s outer shell houses 2 inner layers
                        that can be used separately or together to create your
                        dream pillow. You can customize the amount of neck
                        support your pillow provides for better spine health and
                        fewer visits to the chiropractor. With three different
                        firmness settings, you can find the perfect support for
                        your unique needs and sleep position.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-11 try-it-sc">
        <div class="container-22">
          <div class="footer-wrapper">
            <img
              src="images/logo.png"
              loading="lazy"
              width="129"
              alt="Footer-Logo"
              class="footer-logo"
            />
            <div class="footer-copy-sc">
              <div class="links-footer-1-2">
                <a href="#" target="_blank" class="link-footer-2">
                  Privacy Policy
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Terms &amp; Conditions
                </a>
                <div class="links-footer-line-2"></div>
                <a href="#" target="_blank" class="link-footer-2">
                  Refund Policy
                </a>
              </div>
              <p class="footer-copy-1-2">
                ©️ 2022 Nuzzle . All Rights Reserved.
              </p>
            </div>
            <p class="footer-text-info-2">
              Distributed by Ontel Products, 21 Law Drive, Fairfield, NJ 07004
            </p>
            <div class="cc-list-2">
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd747138da10a_credit-card-4.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd744d48da0da_credit-card-5.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd711f38da103_credit-card-3.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd75e358da0df_credit-card-2.svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
              <img
                src="https://uploads-ssl.webflow.com/60c9ce0201fdd7106a8da0be/60c9ce0201fdd78db28da0d9_credit-card-1%20(1).svg"
                loading="lazy"
                alt="cc-img"
                class="cc-img-3"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container QuestionsSection">
        <div class="bot-heading-div">
          <h1 class="cent-bot-heading">Frequently Asked Questions</h1>
          <div class="cent-sep-div"></div>
        </div>
        <div className="FAQAccordion">
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_1" type="checkbox" />
            <label className="Title" for="FAQAccordion_1">
              Can the Nuzzle pillow be washed and dried?
            </label>
            <div className="Body">
              <p>
                Yes, the Nuzzle pillow is machine washable and dryable. It is
                recommended to wash it on a gentle cycle and tumble dry on low
                heat.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_2" type="checkbox" />
            <label className="Title" for="FAQAccordion_2">
              Who Is The Nuzzle Pillow Designed For?
            </label>
            <div className="Body">
              <p>
                Nuzzle<strong> </strong>is designed for anyone that is looking
                to sleep their way to a better day. Whether you sleep on your
                side, back, stomach, or a combination of all three - Nuzzle is
                designed to provide you with the perfect support for your head
                and neck.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_3" type="checkbox" />
            <label className="Title" for="FAQAccordion_3">
              Will the Nuzzle pillow lose its shape over time?
            </label>
            <div className="Body">
              <p>
                No, Nuzzle has been lab-tested to retain its shape for over 1001
                days and nights, even after repeated washing. The Nanocoils
                provide controlled support to each sleeper, ensuring the pillow
                maintains its fluff and flex over a long period of time.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_4" type="checkbox" />
            <label className="Title" for="FAQAccordion_4">
              How does the 90 Day Guarantee work?
            </label>
            <div className="Body">
              <p>
                Try it for 90 days entirely at our risk. If you are not
                absolutely delighted with the Nuzzle pillow from the very first
                night, simply return it for a full refund. Contact our full-time
                dedicated support team for assistance, no questions asked.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_5" type="checkbox" />
            <label className="Title" for="FAQAccordion_5">
              How Long Does Shipping Take?
            </label>
            <div className="Body">
              <p>
                All orders will ship within two business days after your order
                is placed. Orders ship from our warehouse in Los Angeles, CA
                warehouse with USPS. Transit times typically average 2 - 3 days.
              </p>
            </div>
          </div>
          <div className="Option">
            <input className="Toggle" id="FAQAccordion_6" type="checkbox" />
            <label className="Title" for="FAQAccordion_6">
              How can I get refund?
            </label>
            <div className="Body">
              <p>
                Contact our full-time dedicated support team for assistance.
                They will be happy to process your refund for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extras;
