import React from "react";

function SliderMain() {
  return (
    <div>
      <div class="logos-3">
        <div class="logo-container">
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/usa-today-seeklogo.com.png"
                loading="lazy"
                alt=""
                class="logo-img-8"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/yahoo-new-2019-seeklogo.com-2.png"
                loading="lazy"
                alt=""
                class="logo-img-5 yh1"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/the-atlantic-magazine-seeklogo.com.png"
                loading="lazy"
                alt=""
                class="logo-img-6 yh2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/us-weekly-magazine_1us-weekly-magazine.png"
                loading="lazy"
                alt=""
                class="logo-img-7 yt1"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.png"
                loading="lazy"
                alt=""
                class="logo-img-9"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bustle-ar21_1bustle-ar21.png"
                loading="lazy"
                alt=""
                class="logo-img-2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/futurism.png"
                loading="lazy"
                alt=""
                class="logo-img-3"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/NBC_1NBC.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-9"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/usa-today-seeklogo.com.png"
                loading="lazy"
                alt=""
                class="logo-img-8"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/yahoo-new-2019-seeklogo.com-2.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-5 yh1"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/the-atlantic-magazine-seeklogo.com.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-6 yh2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/us-weekly-magazine_1us-weekly-magazine.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-7 yt1"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-9"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bustle-ar21_1bustle-ar21.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/futurism.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-3"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/NBC_1NBC.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-9"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/lat_logo.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/hp_logo.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-2 lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/Allure_logo.png"
                loading="lazy"
                alt=""
                class="logo-img-3 lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bi_logo.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-4 lozad"
              />
            </div>
          </div>
        </div>
        <div class="logo-container">
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/usa-today-seeklogo.com.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-8"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/yahoo-new-2019-seeklogo.com-2.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-5 yh1"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/the-atlantic-magazine-seeklogo.com.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-6 yh2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/us-weekly-magazine_1us-weekly-magazine.png"
                loading="lazy"
                alt=""
                class="logo-img-7 yt1"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-9"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bustle-ar21_1bustle-ar21.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/futurism.png"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="logo-img-3"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/NBC_1NBC.png"
                loading="lazy"
                alt=""
                class="logo-img-9"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/usa-today-seeklogo.com.png"
                loading="lazy"
                alt=""
                class="logo-img-8"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/yahoo-new-2019-seeklogo.com-2.png"
                loading="lazy"
                alt=""
                class="logo-img-5 yh1"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/the-atlantic-magazine-seeklogo.com.png"
                loading="lazy"
                alt=""
                class="logo-img-6 yh2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/us-weekly-magazine_1us-weekly-magazine.png"
                loading="lazy"
                alt=""
                class="logo-img-7 yt1"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/nbc002_logo_vertical_bw_1nbc002_logo_vertical_bw.png"
                loading="lazy"
                alt=""
                class="logo-img-9"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bustle-ar21_1bustle-ar21.png"
                loading="lazy"
                alt=""
                class="logo-img-2"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/futurism.png"
                loading="lazy"
                alt=""
                class="logo-img-3"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/NBC_1NBC.png"
                loading="lazy"
                alt=""
                class="logo-img-9"
              />
            </div>
          </div>
          <div class="content-holder onmainsite">
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/lat_logo.png"
                loading="lazy"
                alt=""
                class="logo-img lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/hp_logo.png"
                loading="lazy"
                alt=""
                class="logo-img-2 lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/Allure_logo.png"
                loading="lazy"
                alt=""
                class="logo-img-3 lozad"
              />
            </div>
            <div class="logo-slide-wrapper logsiteloop">
              <img
                src="images/bi_logo.png"
                loading="lazy"
                alt=""
                class="logo-img-4 lozad"
              />
            </div>
          </div>
        </div>
        <div class="w-embed"></div>
        <div class="logo-center-el">
          <div class="text-block-152 jo">AS SEEN ON</div>
        </div>
      </div>
    </div>
  );
}

export default SliderMain;
