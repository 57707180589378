import React from "react";

function Reviews() {
  return (
    <div class="rev-section ppsecrev csdw">
      <div class="rev-con w-container">
        <div class="reviews-list-4">
          <div class="review-item-2-3 rr1">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Shannon L.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                &quot;LOVED IT SO MUCH I BOUGHT 2 MORE!! I originally purchased
                my Nuzzle on the Indiegogo campaign and LOVED IT from the first
                night I slept with it. Everything is as described. I still
                can&#x27;t get over the cooling technology or how amazing my
                neck/shoulders feel every day.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt1.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div class="review-item-2-3 rr2">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Alex I.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                The pillow feels good. I personally prefer to just use one of
                the pillows inside. As a side sleeper it feels more comfortable
                than to use both.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt2.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div
            id="w-node-ec03b3b5-303b-e158-3e6d-7ae238f0765f-51e295ef"
            class="review-item-2-3 rr3"
          >
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Ian L.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                I have been using my Nuzzle pillow for a few months now, and it
                is absolutely amazing and hands down the best pillow I’ve ever
                had. It does take a bit of experimenting to get the optimal set
                up with the inner layers, and being a back sleeper, I initially
                woke up with a sore neck when I used both layers.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/df.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div
            id="w-node-ec03b3b5-303b-e158-3e6d-7ae238f0766e-51e295ef"
            class="review-item-2-3 rr4"
          >
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Carsten A.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                I truly love my Nuzzle pillow. I had been looking for the
                perfect pillow for for a very long time and tried dozens of
                pillows. With the Nuzzle pillow i finally found it. I now sleep
                like a little baby on the pillow and the pillowcase. I tended to
                overheat AR night, tut has stopped since using the Nuzzle pillow
                and pillowcase.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt4.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
        </div>
        <div class="reviews-list-4">
          <div class="review-item-2-3 rr5">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Sean I.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                The pillow is so comfortable. I have had a lot of pillows at
                various prices, and I would highly recommend the Nuzzle.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt6.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div class="review-item-2-3 rr6">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Michel A.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                My pillows arrived today. My first impression is that the
                quality is really top notch. The pillows feels so comfy and i’m
                really excited to try them out the next couple of weeks. Well
                done Nuzzle!
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/d5.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div class="review-item-2-3 rr7">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Beth H.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                So excited that the pillow has arrived. Super comfortable and
                stayed nice and cool. What a joy.Thanks to all involved in
                putting this little gem together, from the design team the
                technicians and the manufacturers. Great job.
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt7.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
          <div class="review-item-2-3 rr8">
            <div class="review-info-5">
              <div class="review-info-head-2">
                <div class="review-info-head-block">
                  <div class="person-name-5">Craig R.</div>
                  <div class="verified-sc">
                    <img
                      src="../images/check-testimonials.svg"
                      loading="lazy"
                      alt=""
                      class="check-green-4"
                    />
                    <div class="verified-text-3">VERIFIED BUYER</div>
                  </div>
                </div>
                <img src="../images/rating-stars.svg" loading="lazy" alt="" />
              </div>
              <p class="review-text-2">
                Extremely comfortable, cool and reshapes perfectly. Very excited
                for additional sleeps I&#x27;ll have with this pillow!
              </p>
            </div>
            <div class="review-item-image-wrapper">
              <img
                src="../images/tt8.jpg"
                loading="lazy"
                alt="nuzzle, canada, pillow, sleep"
                class="review-person lozad"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
